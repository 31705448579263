import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import * as TokenSvc from 'services/token.svc'
import { Post } from 'services/http.svc'
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
export const ParamountAuthContext = createContext({})

const useAuthSessionStorage = () => {
  const [signInWith, _setSignInWith] = useState()
  const [authCode, _setAuthCode] = useState()
  const [accessToken, _setAccessToken] = useState()

  useEffect(() => {
    const siw = TokenSvc.getSignInWith()
    const ac = TokenSvc.getAuthCode()
    const at = TokenSvc.getAccessToken()
    _setSignInWith(siw)
    _setAuthCode(ac)
    _setAccessToken(at)
  }, [])

  const setSignInWith = useCallback(
    (value) => {
      if (value) {
        TokenSvc.setSignInWith(value)
      } else {
        TokenSvc.removeSignInWith()
      }
      _setSignInWith(value)
    },
    [_setSignInWith],
  )

  const setAccessToken = useCallback(
    (value) => {
      if (value) {
        TokenSvc.setAccessToken(value)
      } else {
        TokenSvc.removeAccessToken()
      }
      _setAccessToken(value)
    },
    [_setAccessToken],
  )

  const setAuthCode = useCallback(
    (value) => {
      if (value) {
        TokenSvc.setAuthCode(value)
      } else {
        TokenSvc.removeAuthCode()
      }
      _setAuthCode(value)
    },
    [_setAuthCode],
  )

  return {
    signInWith,
    setSignInWith,
    authCode,
    setAuthCode,
    accessToken,
    setAccessToken,
  }
}

const tokenGuts = (rawToken) => {
  if (rawToken) {
    const [, raw] = rawToken.split('.')
    if (raw) {
      return JSON.parse(atob(raw))
    }
  }
  return null
}

const ParamountAuthProvider = ({ children }) => {
  const { setSignInWith, setAuthCode, accessToken, setAccessToken } =
    useAuthSessionStorage()
  const [initialised, setInitialised] = useState(false)
  const initFirebase = useCallback(() => {
    const firebaseConfig = JSON.parse(
      atob(process.env.REACT_APP_FIREBASE_CONFIG_BASE64).replace(/'/g, ''),
    )
    return initializeApp(firebaseConfig)
  }, [])

  useEffect(() => {
    initFirebase()
    setInitialised(true)
  }, [])

  const fetchAccessToken = async (idToken, callback) => {
    try {
      const body = TokenSvc.getInviteCode('INVITE_CODE_TOKEN')
        ? { inviteCode: TokenSvc.getInviteCode('INVITE_CODE_TOKEN') }
        : null
      const response = await Post('auth/token', body, idToken)
      const { token } = response
      setAccessToken(token)
      if (callback) {
        callback()
      }
      return token
    } catch (err) {
      console.error(err)
    }
  }

  const isLoggedIn = useMemo(() => {
    if (accessToken) {
      const token = tokenGuts(accessToken)
      const exp = (token?.exp || 0) * 1000
      if (exp > new Date().getTime()) {
        return true
      }
    }
    return false
  }, [accessToken])

  const [userRoles, userCompanyId] = useMemo(() => {
    if (accessToken) {
      const token = tokenGuts(accessToken)
      return [token?.roles || [], token?.company]
    }
    return []
  }, [accessToken])

  const logout = async () => {
    await getAuth().signOut()
    setSignInWith(null)
    setAuthCode(null)
    setAccessToken(null)
  }

  const handleSignIn = async (signInWith, authCode, successCallback) => {
    setSignInWith(signInWith)
    setAuthCode(authCode)
    return fetchAccessToken(authCode, successCallback)
  }

  return (
    <ParamountAuthContext.Provider
      value={{
        handleSignIn,
        logout,
        isLoggedIn,
        userRoles,
        userCompanyId,
        initialised,
        setAccessToken,
        tokenGuts,
      }}
    >
      {children}
    </ParamountAuthContext.Provider>
  )
}

export default ParamountAuthProvider

export const useParamountAuth = () => {
  const context = useContext(ParamountAuthContext)
  if (!context) {
    throw new Error(
      'Cannot use useParamountAuth hook outside of ParamountAuthProvider',
    )
  }
  return context
}
