import React from 'react'
import { useParams } from 'react-router-dom'
import { getCompanyEmployeeDetail, getClientById } from 'services/clients.svc'
import { useQuery } from '@tanstack/react-query'
import { roles } from '../../components/constants'
import { PageTitle } from 'context/PageTitleContext'
import { BackButton } from 'components'
import './UserDetails.scss'

export default function UserDetails() {
  const { employeeId, clientId } = useParams()
  const { data: employeeDetails } = useQuery(['employeedetails'], () =>
    getCompanyEmployeeDetail(clientId, employeeId),
  )
  const { data: employerDetails } = useQuery(['clientdetail'], () =>
    getClientById(clientId),
  )
  const userRole = employeeDetails?.userRole?.role
    ? roles[employeeDetails.userRole.role]
    : ''

  return (
    <div key={employeeId} className="employee-details">
      <PageTitle title="Employee Details" />
      <BackButton />
      <div className="grid">
        <div>
          <h3>Personal details</h3>
          <label>First Name</label>
          <p>{employeeDetails?.firstName || ''}</p>
          <label>Surname</label>
          <p>{employeeDetails?.surname || ''}</p>
          <label>Phone Number</label>
          <p>{employeeDetails?.phone || ''}</p>
          <label>Email Address</label>
          <p>{employeeDetails?.contactEmail || ''}</p>
        </div>
        <div>
          <h3>Business Details</h3>
          <label>Employer</label>
          <p>
            {employerDetails?.image?.url && (
              <img src={employerDetails.image.url} />
            )}
            {employerDetails?.name || ''}
          </p>
          <label>Role</label>
          <p>{userRole || ''}</p>
        </div>
      </div>
    </div>
  )
}
