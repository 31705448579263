import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { BackButton } from 'components'
import { useParams } from 'react-router-dom'
import { PageTitle } from 'context/PageTitleContext'
import EditClientPage from './EditClientPage'
import { getClientById } from 'services/clients.svc'
import './Edit.scss'

export default function Edit() {
  const { companyId } = useParams()
  const { data, isLoading } = useQuery(['editCompany', companyId], () =>
    getClientById(companyId),
  )

  return (
    <div className="edit-company">
      <PageTitle title="Edit Company" />
      <BackButton />
      {isLoading ? (
        <article aria-busy="true">Loading, Please Wait</article>
      ) : data ? (
        <EditClientPage companyId={companyId} clientDetail={data} />
      ) : (
        <h1>Something went wrong {':('}</h1>
      )}
    </div>
  )
}
