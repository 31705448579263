import { ProjectSiteStatus_Enum as SiteStatus } from '@paramount-prestart/shared'
export const siteStatusOptions = [
  { text: 'Ongoing', value: SiteStatus.ONGOING, background: '#2f855a' },
  { text: 'On Hold', value: SiteStatus.ON_HOLD, background: '#dd6b20' },
  { text: 'Completed', value: SiteStatus.COMPLETED, background: '#c53030' },
]
const _siteStatus = {}
_siteStatus[SiteStatus.ONGOING] = 'Ongoing'
_siteStatus[SiteStatus.ON_HOLD] = 'On Hold'
_siteStatus[SiteStatus.COMPLETED] = 'Completed'
export const siteStatus = _siteStatus

export const sortingSiteBySuburb = [
  {
    text: 'Sort A-Z',
    value: 'suburb.string.asc',
  },
  {
    text: 'Sort Z-A',
    value: 'suburb.string.desc',
  },
]

export const sortingSiteBySupervisor = [
  {
    text: 'Sort A-Z',
    value: 'supervisorName.string.asc',
  },
  {
    text: 'Sort Z-A',
    value: 'supervisorName.string.desc',
  },
]

export const sortingSiteByClient = [
  {
    text: 'Sort A-Z',
    value: 'clientName.string.asc',
  },
  {
    text: 'Sort Z-A',
    value: 'clientName.string.desc',
  },
]

export const sortingSiteByNumber = [
  {
    text: 'Sort Low-High',
    value: 'siteNumber.number.asc',
  },
  {
    text: 'Sort High-Low',
    value: 'siteNumber.number.desc',
  },
]

export const filterSiteByStatus = [
  {
    text: siteStatus[SiteStatus.ONGOING],
    value: `status.string.${SiteStatus.ONGOING}`,
  },
  {
    text: siteStatus[SiteStatus.ON_HOLD],
    value: `status.string.${SiteStatus.ON_HOLD}`,
  },
  {
    text: siteStatus[SiteStatus.COMPLETED],
    value: `status.string.${SiteStatus.COMPLETED}`,
  },
]
