import React from 'react'
import { FolderIcon } from 'components/Icons'
import './EmptyList.scss'

const EmptyList = () => {
  return (
    <div className="empty-list-container">
      <FolderIcon width="35" height="35" fill={'var(--font-color)'} />
      <h1>No Data Found</h1>
    </div>
  )
}

export default EmptyList
