import { useCallback, useMemo, useState } from 'react'

const stringSort = (x, y, propertyName) =>
  (x[propertyName] || '').localeCompare(y[propertyName] || '')

const numberSort = (x, y, propertyName) =>
  (x[propertyName] || 0) - (y[propertyName] || 0)

const dateSort = (x, y, propertyName) =>
  Date.parse(y[propertyName]) - Date.parse(x[propertyName])

const searchData = (values, searchTerm) =>
  values.some((val) =>
    (val || '').toString().toLowerCase().includes(searchTerm),
  )

const searchList = (list, searchTerm) => {
  if (searchTerm) {
    const lowerSearch = searchTerm.toLowerCase()
    return list.filter((f) => searchData(Object.values(f), lowerSearch))
  }
  return list
}

const booleanFilter = (list, prop, filterValue) =>
  list.filter((f) => f[prop] === (filterValue === 'true'))

const stringFilter = (list, prop, value) =>
  list.filter((f) => f[prop] && f[prop].toLowerCase() === value.toLowerCase())

const filterList = (list, filterKey) => {
  const [prop, datatype, value] = filterKey.split('.')
  if (prop && datatype && value) {
    return datatype === 'boolean'
      ? booleanFilter(list, prop, value)
      : stringFilter(list, prop, value)
  }
  return list
}

const sortList = (list, sortKey) => {
  const [property, datatype, direction] = sortKey.split('.')
  if (property && datatype && direction) {
    const sortFn =
      datatype === 'string'
        ? stringSort
        : datatype === 'date'
        ? dateSort
        : numberSort
    return direction === 'asc'
      ? list.sort((x, y) => sortFn(x, y, property))
      : list.sort((x, y) => sortFn(y, x, property))
  }
  return list
}

export const useTableActions = (data) => {
  const [actionState, setActionState] = useState({
    search: '',
    sort: '',
    filter: '',
    selectedItemIds: [],
  })
  const list = useMemo(() => {
    let currentList = searchList(data || [], actionState.search)
    currentList = filterList(currentList, actionState.filter)
    return sortList(currentList, actionState.sort)
  }, [actionState, data])

  const allChecked = useMemo(
    () =>
      actionState.selectedItemIds.length === list.length && list.length !== 0,
  )
  const setSearch = useCallback(
    (value) => setActionState((prev) => ({ ...prev, search: value || '' })),
    [setActionState],
  )
  const setFilter = useCallback(
    (value) =>
      setActionState((prev) => ({
        ...prev,
        filter: value && actionState.filter !== value ? value : '',
      })),
    [setActionState, actionState.filter],
  )
  const setSort = useCallback(
    (value) => setActionState((prev) => ({ ...prev, sort: value || '' })),
    [setActionState],
  )
  const setSelectedItemIds = useCallback(
    (value) =>
      setActionState((prev) => ({ ...prev, selectedItemIds: value || '' })),
    [setActionState],
  )
  const toggleAllChecked = useCallback(() => {
    if (!allChecked) {
      setActionState({
        ...actionState,
        selectedItemIds: list.map((m) => m._id),
      })
    } else {
      setActionState({ ...actionState, selectedItemIds: [] })
    }
  }, [allChecked, setActionState, list])

  const toggleListItemSelected = useCallback(
    (id) => {
      const found = actionState.selectedItemIds.find((f) => f === id)
      const tmp = found
        ? actionState.selectedItemIds.filter((f) => f !== id)
        : [...actionState.selectedItemIds, id]
      setActionState({ ...actionState, selectedItemIds: tmp })
    },
    [actionState, setActionState],
  )

  return {
    list,
    tableActions: actionState,
    allChecked,
    setSearch,
    setFilter,
    setSort,
    setSelectedItemIds,
    toggleAllChecked,
    toggleListItemSelected,
  }
}
