import React, { useState, useMemo } from 'react'
import { useMutation } from '@tanstack/react-query'
import { BackButton, FilePicker } from 'components'
import { PageTitle } from 'context/PageTitleContext'
import { useNavigate } from 'react-router-dom'
import { newClient, validateClient } from '@paramount-prestart/shared'
import { addClient } from 'services/clients.svc'
import { useFileUploadAndSave } from 'hooks/useFileUploadAndSave'
import { toast } from 'react-toastify'
import './Add.scss'
import {
  PLACEHOLDER_ABN,
  PLACEHOLDER_BUILDER_REG_NUMBER,
  PLACEHOLDER_COMPANY_NAME,
  PLACEHOLDER_EMAIL,
  PLACEHOLDER_ONE_LINE_ADDRESS,
  PLACEHOLDER_PHONE,
} from '@paramount-prestart/shared/placeholders'

export default function Add() {
  const [values, setValues] = useState(newClient)
  const [selectedFile, setSelectedFile] = useState(undefined)
  const navigate = useNavigate()
  const errors = useMemo(() => validateClient(values), [values])
  const canSubmit = useMemo(() => {
    const hasNulls = Object.keys(values).some(
      (x) => x !== 'image' && values[x] === null,
    )
    const hasErrors = Object.keys(errors || {}).length > 0
    return !(hasNulls || hasErrors)
  }, [errors])

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setValues({
      ...values,
      [name]: value,
    })
  }

  const onFileSelected = ({ event, guid }) => {
    if ((!event?.target?.files?.length && !selectedFile) || !event) {
      setSelectedFile(undefined)
      setValues({ ...values, image: null })
    } else if (event?.target?.files?.length) {
      const fileData = event.target.files[0]
      const file = {
        friendlyName: fileData.name,
        fileKey: `${guid}.${fileData.name.split('.').pop()}`,
        type: 'companyProfile',
      }
      setValues({ ...values, image: file })
      setSelectedFile(fileData)
    }
  }

  const addClientMutation = useMutation(() => addClient(values), {
    onSuccess: async (data) => {
      toast.success('Client added successfully')
      navigate(`/clients/${data._id}/employees/add`)
    },
    onError: async (err) => {
      toast.error(err.message)
      console.error('Failed to add client', err)
    },
  })

  const saveMutation = useFileUploadAndSave(selectedFile, values.image, () =>
    addClientMutation.mutate(),
  )

  return (
    <div className="body-container">
      <PageTitle title="New Client" />
      <div className="create-client">
        <BackButton />
        <div className="body-content">
          <h3>Create New Client</h3>
          <div className="grid">
            <div>
              <label>
                Company Name
                <input
                  type="text"
                  name="name"
                  value={values.name || ''}
                  aria-invalid={
                    values.name !== null ? Boolean(errors.name) : 'none'
                  }
                  placeholder={PLACEHOLDER_COMPANY_NAME}
                  onChange={handleInputChange}
                />
              </label>
              <small>{errors?.name}</small>
              <label>
                ABN
                <input
                  type="text"
                  name="abn"
                  value={values.abn || ''}
                  aria-invalid={
                    values.abn !== null ? Boolean(errors.abn) : 'none'
                  }
                  placeholder={PLACEHOLDER_ABN}
                  onChange={handleInputChange}
                />
              </label>
              <small>{errors?.abn}</small>
              <label>
                Builders Registration No.
                <input
                  type="text"
                  name="buildersRegNumber"
                  aria-invalid={
                    values.buildersRegNumber !== null
                      ? Boolean(errors.buildersRegNumber)
                      : 'none'
                  }
                  value={values.buildersRegNumber || ''}
                  placeholder={PLACEHOLDER_BUILDER_REG_NUMBER}
                  onChange={handleInputChange}
                />
              </label>
              <small>{errors?.buildersRegNumber}</small>
              <label>
                Office Phone Number
                <input
                  type="text"
                  name="phone"
                  value={values.phone || ''}
                  aria-invalid={
                    values.phone !== null ? Boolean(errors.phone) : 'none'
                  }
                  placeholder={PLACEHOLDER_PHONE}
                  onChange={handleInputChange}
                />
              </label>
              <small>{errors?.phone}</small>
              <label>
                Office Email Address
                <input
                  type="text"
                  name="email"
                  value={values.email || ''}
                  aria-invalid={
                    values.email !== null ? Boolean(errors.email) : 'none'
                  }
                  placeholder={PLACEHOLDER_EMAIL}
                  onChange={handleInputChange}
                />
              </label>
              <small>{errors?.email}</small>
              <label>
                Office Address
                <input
                  type="text"
                  name="address"
                  value={values.address || ''}
                  aria-invalid={
                    values.address !== null ? Boolean(errors.address) : 'none'
                  }
                  placeholder={PLACEHOLDER_ONE_LINE_ADDRESS}
                  onChange={handleInputChange}
                />
              </label>
              <small>{errors?.address}</small>
              <label>
                Postal Address
                <input
                  type="text"
                  name="postalAddress"
                  value={values.postalAddress || ''}
                  aria-invalid={
                    values.postalAddress !== null
                      ? Boolean(errors.postalAddress)
                      : 'none'
                  }
                  placeholder={PLACEHOLDER_ONE_LINE_ADDRESS}
                  onChange={handleInputChange}
                />
              </label>
              <small>{errors?.postalAddress}</small>
            </div>
            <FilePicker
              fileMetadata={values?.image}
              selectedFile={selectedFile}
              onFileSelected={onFileSelected}
              fileType="companyProfile"
              buttonLabel="Choose Image"
              iconColor={'var(--font-color)'}
            />
          </div>
        </div>
        <button
          type="button"
          className="primary"
          onClick={() => saveMutation.mutate()}
          disabled={!canSubmit}
          aria-busy={addClientMutation.isLoading}
        >
          {addClientMutation.isLoading ? 'Please wait' : 'Next'}
        </button>
      </div>
    </div>
  )
}
