import { PPE_Enum as PpeEnum } from '@paramount-prestart/shared'
import {
  DustMask,
  EyeProtection,
  FirstAid,
  Gloves,
  HardHat,
  HearingProtection,
  HighVis,
  SafetyBoots,
  SafetyHarness,
  WeldingMask,
} from 'components/Icons'

export default function PPE({ icon }) {
  switch (icon) {
    case PpeEnum.DustMask:
      return <DustMask />
    case PpeEnum.EyeProtection:
      return <EyeProtection />
    case PpeEnum.FirstAid:
      return <FirstAid />
    case PpeEnum.Gloves:
      return <Gloves />
    case PpeEnum.HardHat:
      return <HardHat />
    case PpeEnum.HearingProtection:
      return <HearingProtection />
    case PpeEnum.HighVis:
      return <HighVis />
    case PpeEnum.SafetyBoots:
      return <SafetyBoots />
    case PpeEnum.SafetyHarness:
      return <SafetyHarness />
    case PpeEnum.WeldingMask:
      return <WeldingMask />
    default:
      return null
  }
}
