import React from 'react'
import { BackButton } from 'components'
import { PageTitle } from 'context/PageTitleContext'
import { useParams, useNavigate } from 'react-router-dom'
import { useQuery, useMutation } from '@tanstack/react-query'
import { newProject } from '@paramount-prestart/shared'
import { addProject, getClientEmployees } from 'services/clients.svc'
import { toast } from 'react-toastify'
import './Add.scss'
import ProjectSiteForm from '../components/ProjectSiteForm'

const newProjectState = newProject()

export default function Add() {
  const { companyId } = useParams()
  const navigate = useNavigate()
  const { data: employees } = useQuery(['clientEmployees'], () =>
    getClientEmployees(companyId),
  )

  const addProjectMutate = useMutation(
    (values) => addProject(companyId, { ...values }),
    {
      onSuccess: async (data) => {
        navigate(`/company/${companyId}/projectsites`)
        toast.success('ProjectSite added successfully')
      },
      onError: async (err) => {
        console.error('Adding Project Details failed', err.message)
        toast.error('Failed to add ProjectSite')
      },
    },
  )

  return (
    <div className="body-container">
      <PageTitle title="New Project Site" />
      <div className="create-project">
        <BackButton />
        <h3>Create New Project Site</h3>
        <ProjectSiteForm
          employees={employees}
          initialState={newProjectState}
          mode="add"
          submitMutation={addProjectMutate}
        />
      </div>
    </div>
  )
}
