import { LogoutIcon } from 'components/Icons'
import { useParamountAuth } from 'context/ParamountAuthContext'
import { useNavigate } from 'react-router-dom'

const LogoutButton = () => {
  const { logout } = useParamountAuth()
  const navigate = useNavigate()

  const onLogout = () => {
    logout()
    navigate('/')
  }

  return (
    <button onClick={onLogout} className={'primary'}>
      <LogoutIcon fill={'var(--color-black)'} />
      Logout
    </button>
  )
}

export default LogoutButton
