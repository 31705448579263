/* global localStorage */
const storage = (key, value, remove = false) => {
  if (key && value) {
    localStorage.setItem(key, value)
    return value
  }
  if (key && remove) {
    return localStorage.removeItem(key)
  }
  if (key) {
    return localStorage.getItem(key) || null
  }
  return null
}

const PARAMOUNT_AUTHCODE = 'PARAMOUNT_AUTHCODE'
export const getAuthCode = () => storage(PARAMOUNT_AUTHCODE)
export const setAuthCode = (value) => storage(PARAMOUNT_AUTHCODE, value)
export const removeAuthCode = () => storage(PARAMOUNT_AUTHCODE, null, true)

const PARAMOUNT_ACCESSTOKEN = 'PARAMOUNT_ACCESSTOKEN'
export const getAccessToken = () => storage(PARAMOUNT_ACCESSTOKEN)
export const setAccessToken = (value) => storage(PARAMOUNT_ACCESSTOKEN, value)
export const removeAccessToken = () =>
  storage(PARAMOUNT_ACCESSTOKEN, null, true)

const INVITE_CODE_TOKEN = 'INVITE_CODE_TOKEN'
export const getInviteCode = () => storage(INVITE_CODE_TOKEN)
export const setInviteCode = (value) => storage(INVITE_CODE_TOKEN, value)
export const removeInviteCode = () => storage(INVITE_CODE_TOKEN, null, true)

const PARAMOUNT_SIGNINWITH = 'PARAMOUNT_SIGNINWITH'
export const getSignInWith = () => storage(PARAMOUNT_SIGNINWITH)
export const setSignInWith = (value) => storage(PARAMOUNT_SIGNINWITH, value)
export const removeSignInWith = () => storage(PARAMOUNT_SIGNINWITH, null, true)
