import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  getClientEmployees,
  getProjectById,
  updateProject,
} from 'services/clients.svc'
import { BackButton } from 'components'
import { PageTitle } from 'context/PageTitleContext'
import './Edit.scss'
import ProjectSiteForm from '../components/ProjectSiteForm'
import {
  deleteFileIfItExists,
  getStructuredProjectSite,
} from 'common/functions'
import { toast } from 'react-toastify'

const Edit = () => {
  const { projectSiteId, companyId } = useParams()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { data: projectSiteDetails } = useQuery(
    ['projectSiteDetails', projectSiteId],
    () => {
      return getProjectById(projectSiteId, companyId).then((obj) => {
        delete obj.supervisorName
        delete obj.supervisorDetails
        delete obj.supervisorPhone
        return obj
      })
    },
  )
  const { data: employees } = useQuery(['clientEmployees'], () =>
    getClientEmployees(companyId),
  )

  const updateSiteDetailsMutate = useMutation(
    (projectState) => updateProject(companyId, projectSiteId, projectState),
    {
      onSuccess: async (data) => {
        if (data?.fileDeleteSASToken) {
          await deleteFileIfItExists(data.fileDeleteSASToken)
        }
        const projectSiteData = getStructuredProjectSite(data)
        queryClient.setQueryData(
          ['projectSiteDetails', projectSiteId],
          () => projectSiteData,
        )
        toast.success('ProjectSite updated successfully')
        navigate(`/company/${companyId}/projectsites/${projectSiteId}`)
      },
      onError: async (err) => {
        toast.error('Failed to update the information')
        console.error('Failed to update', err)
      },
    },
  )

  return (
    <div className="body-container">
      <PageTitle title="Projects" />
      <div className="edit-project-site">
        <BackButton />
        <h3>Site Information</h3>
        {projectSiteDetails ? (
          <ProjectSiteForm
            employees={employees}
            initialState={projectSiteDetails}
            mode="edit"
            submitMutation={updateSiteDetailsMutate}
          />
        ) : (
          <article aria-busy="true">Loading, Please Wait</article>
        )}
      </div>
    </div>
  )
}

export default Edit
