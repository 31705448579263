import React, { useState, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { updateMyProfile } from 'services/clients.svc'
import { validateEditProfile, Placeholders } from '@paramount-prestart/shared'
import { toast } from 'react-toastify'
import './EditProfileForm.scss'

const {
  PLACEHOLDER_EMAIL,
  PLACEHOLDER_FIRSTNAME,
  PLACEHOLDER_SURNAME,
  PLACEHOLDER_PHONE,
} = Placeholders

const EditProfileForm = ({ userDetail }) => {
  const { profileId } = useParams()
  const navigate = useNavigate()
  const [userInfo, setUserInfo] = useState(userDetail)
  const queryClient = useQueryClient()

  const errors = useMemo(() => validateEditProfile(userInfo), [userInfo])

  const canSubmit = useMemo(
    () =>
      Object.values(userInfo).findIndex((x) => x === null) === -1 ||
      userInfo?.businessDetails === null
        ? !errors || Object.keys(errors).length < 1
        : false,
    [errors],
  )

  const handleInput = ({
    e: {
      target: { name, value },
    },
  }) => {
    setUserInfo({ ...userInfo, [name]: value })
  }

  const editProfileMutation = useMutation(() => updateMyProfile(userInfo), {
    onSuccess: (data) => {
      queryClient.setQueryData(['user', profileId], () => data)
      toast.success('Details updated successfully')
      navigate('/profile')
    },
    onError: async (err) => {
      toast.error('Failed to update the info')
      console.error('Failed to update', err)
    },
  })

  return (
    <>
      <div
        className={`grid edit-profile-grid ${
          userInfo?.role === 'CompanySupervisor'
            ? ''
            : 'edit-profile-grid-unset'
        }`}
      >
        <div>
          <h3>Personal Details</h3>
          <label> First name</label>
          <input
            type="text"
            name="firstName"
            onChange={(e) => handleInput({ e })}
            aria-invalid={
              userInfo.firstName !== null ? Boolean(errors.firstName) : 'none'
            }
            value={userInfo?.firstName || ''}
            placeholder={PLACEHOLDER_FIRSTNAME}
            required
          />
          <small>{errors?.firstName}</small>

          <label>Surname </label>
          <input
            type="text"
            name="surname"
            onChange={(e) => handleInput({ e })}
            value={userInfo?.surname || ''}
            aria-invalid={
              userInfo.surname !== null ? Boolean(errors.surname) : 'none'
            }
            placeholder={PLACEHOLDER_SURNAME}
            required
          />
          <small>{errors?.surname}</small>

          <label>Phone Number </label>
          <input
            type="text"
            name="phone"
            onChange={(e) => handleInput({ e })}
            aria-invalid={
              userInfo.phone !== null ? Boolean(errors.phone) : 'none'
            }
            placeholder={PLACEHOLDER_PHONE}
            value={userInfo?.phone || ''}
          />
          <small>{errors?.phone}</small>

          <label>Email Address </label>
          <input
            type="email"
            name="contactEmail"
            onChange={(e) => handleInput({ e })}
            aria-invalid={
              userInfo.contactEmail !== null
                ? Boolean(errors.contactEmail)
                : 'none'
            }
            placeholder={PLACEHOLDER_EMAIL}
            value={userInfo?.contactEmail || ''}
          />
          <small>{errors?.contactEmail}</small>
        </div>
      </div>
      <button
        className="primary"
        onClick={() => editProfileMutation.mutate()}
        type="submit"
        disabled={!canSubmit}
        aria-busy={editProfileMutation.isLoading}
      >
        {editProfileMutation.isLoading ? 'Please wait' : 'Save Edits'}
      </button>
    </>
  )
}

export default EditProfileForm
