import React from 'react'
import './NavBar.scss'
import { useLocation, NavLink } from 'react-router-dom'
import { useNavBarItem } from 'hooks/useNavBarItem'
import { useParamountAuth } from 'context/ParamountAuthContext'
import { VersionInfo } from 'components'
import { checkActive } from 'common/functions'
import LogoutButton from 'components/buttons/LogoutButton'

function NavBar() {
  const location = useLocation()
  const { userRoles } = useParamountAuth()
  const navItems = useNavBarItem()

  if (
    location.pathname === '/' ||
    location.pathname === '/accept_invitation' ||
    location.pathname === '/unauthorised_role'
  ) {
    return null
  }

  return (
    <aside className="nav-container">
      <nav>
        <ul>
          {navItems.map((item) => {
            const isAllowed = userRoles?.some((role) =>
              item.allowedRoles?.includes(role),
            )
            if (isAllowed) {
              return (
                <li key={item.name}>
                  <NavLink
                    to={item.page}
                    className={({ isActive }) =>
                      isActive ? 'active' : checkActive(item.slug, location)
                    }
                    end
                  >
                    <item.icon fill={item.fill} width={24} />
                    <p>{item.name}</p>
                  </NavLink>
                </li>
              )
            } else {
              return null
            }
          })}
        </ul>
      </nav>
      <div className="navbar-footer">
        <LogoutButton />
        <VersionInfo />
      </div>
    </aside>
  )
}

export default NavBar
