export const sortingSWMSByName = [
  {
    text: 'Sort Most Recent',
    value: 'fullName.string.recent',
  },
  {
    text: 'Sort A-Z',
    value: 'fullName.string.asc',
  },
  {
    text: 'Sort Z-A',
    value: 'fullName.string.desc',
  },
]

export const sortingSWMSByNumber = [
  {
    text: 'Sort Low-High',
    value: 'siteNumber.number.asc',
  },
  {
    text: 'Sort High-Low',
    value: 'siteNumber.number.desc',
  },
]

export const sortingSWMSByDate = [
  {
    text: 'Sort New-Old',
    value: 'createdAt.date.asc',
  },
  {
    text: 'Sort Old-New',
    value: 'createdAt.date.desc',
  },
]

export const sortingSWMSByStatus = [
  {
    text: 'For Review',
    value: 'reviewedAt.boolean.false',
  },
  {
    text: 'Reviewed',
    value: 'reviewedAt.boolean.true',
  },
]

export const swmsStatusOptions = [
  { text: 'Reviewed', value: 'reviewed', background: 'var(--color-cyan)' },
  {
    text: 'For Review',
    value: 'forReview',
    background: 'var(--color-orange-500)',
  },
]

export const swmsRiskConsequence = {
  1: {
    name: 'Insignificant',
    value: 'No injuries/minimal financial loss',
    color: 'var(--color-green-700)',
  },
  2: {
    name: 'Minor',
    value: 'First Aid treatment/medium financial loss',
    color: 'var(--color-green-400)',
  },
  3: {
    name: 'Moderate',
    value: 'Medical Treatment / high financial loss',
    color: 'var(--color-yellow-500)',
  },
  4: {
    name: 'Major',
    value: 'Hospital / Large financial loss',
    color: 'var(--color-orange-500)',
  },
  5: {
    name: 'Catastrophic',
    value: 'Death / major financial loss',
    color: 'var(--color-red-700)',
  },
}

export const swmsRiskLikelihood = {
  1: {
    name: 'Rare',
    value:
      'Conceivable, but only on extreme circumstances / once every 100 years',
    color: 'var(--color-green-700)',
  },
  2: {
    name: 'Unlikely',
    value: "Hasn't happened yet, but could / once every 10 years",
    color: 'var(--color-green-400)',
  },
  3: {
    name: 'Possible',
    value: 'Could happen / known to happen once a year',
    color: 'var(--color-yellow-500)',
  },
  4: {
    name: 'Likely',
    value: 'could easily happen / once a month',
    color: 'var(--color-orange-500)',
  },
  5: {
    name: 'Almost Certain',
    value: 'Often occurs / once a week',
    color: 'var(--color-red-700)',
  },
}
