import React from 'react'
import { NavLink } from 'react-router-dom'
import { BackIcon } from 'components/Icons'
import './BackButton.scss'

export default function BackButton() {
  return (
    <NavLink to={-1} className="back-btn">
      <BackIcon fill={'var(--font-color)'} />
      Back
    </NavLink>
  )
}
