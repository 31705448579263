export const PLACEHOLDER_EMAIL = `e.g. email@example.com`
export const PLACEHOLDER_PHONE = `e.g. 0412345678`
export const PLACEHOLDER_ABN = `e.g. 92 658 050 670`
export const PLACEHOLDER_BUILDER_REG_NUMBER = `e.g. BC11111`
export const PLACEHOLDER_POSTCODE = `e.g. 6000`
export const PLACEHOLDER_SUBURB = `e.g. Perth`
export const PLACEHOLDER_STREET = `e.g. 1 Fake St`
export const PLACEHOLDER_FIRSTNAME = `e.g. Jon`
export const PLACEHOLDER_SURNAME = `e.g. Citizen`
export const PLACEHOLDER_ONE_LINE_ADDRESS = `e.g. 1 Fake St, Perth, 6000`
export const PLACEHOLDER_COMPANY_NAME = `e.g. PPS Building`
export const PLACEHOLDER_CONTACT_NAME = `e.g. Jane Citizen`