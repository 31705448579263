import Clients from './Clients'
import ClientAdd from './Add/Add'
import ClientAddAdmin from './Users/Add/Add'
import UserDetails from './Users/Details/UserDetails'
import Details from './Details/Details'
import ClientEdit from './Edit/Edit'

export default {
  Clients,
  ClientAdd,
  ClientAddAdmin,
  Details,
  ClientEdit,
  UserDetails,
}
