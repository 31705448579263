import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { BackButton } from 'components'
import { getCompanyEmployeeDetail } from 'services/clients.svc'
import './Details.scss'
import { PageTitle } from 'context/PageTitleContext'

const Details = () => {
  const { employeeId, companyId } = useParams()
  const { data } = useQuery(['employeeDetail'], () =>
    getCompanyEmployeeDetail(companyId, employeeId),
  )

  return (
    <div className="employee-details-container body-container">
      <PageTitle title="Employees" />
      <BackButton />
      {data ? (
        <div
          key={employeeId}
          className={`grid ${
            data?.userRole.role === 'CompanySupervisor' ? '' : 'grid-unset'
          }`}
        >
          <div>
            <h3>Personal Details</h3>
            <label>First Name</label>
            <p>{data?.firstName || ''}</p>
            <label>Surname</label>
            <p>{data?.surname || ''}</p>
            <label>Phone Number</label>
            <p>{data?.phone || ''}</p>
            <label>Email Address</label>
            <p>{data?.contactEmail || ''}</p>
            <label>Role</label>
            <p>{data?.userRole?.role || ''}</p>
          </div>
        </div>
      ) : (
        <article aria-busy="true">Loading, Please Wait</article>
      )}
    </div>
  )
}

export default Details
