import React, { useMemo, useRef } from 'react'
import {
  UploadIcon,
  GalleryIcon,
  ResetIcon,
  DeleteIcon,
} from 'components/Icons'
import { fileTypes } from '@paramount-prestart/shared'
import './FilePicker.scss'

const generateAGuid = () => {
  let dt = new Date().getTime()
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    /[xy]/g,
    function (c) {
      const r = (dt + Math.random() * 16) % 16 | 0
      dt = Math.floor(dt / 16)
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
    },
  )
  return uuid
}

export default function FilePicker({
  fileMetadata,
  selectedFile,
  onFileSelected,
  fileType,
  buttonLabel,
  iconColor,
}) {
  const fileRef = useRef()
  const guid = useMemo(() => generateAGuid(), [])
  const imageOrFilesClass = fileTypes[fileType] !== '.pdf' ? 'image' : 'files'
  const imageOrIconColor = selectedFile || fileMetadata

  const imageUrl = useMemo(() => {
    // the fileMetadata from the server will have the SAS token generated in the url property
    const url = selectedFile
      ? URL.createObjectURL(selectedFile)
      : fileMetadata?.url || ''
    URL.revokeObjectURL(selectedFile)
    return url
  }, [fileMetadata, selectedFile])

  const fileName = useMemo(() => {
    return selectedFile ? selectedFile.name : fileMetadata?.friendlyName || ''
  }, [fileMetadata, selectedFile])

  const onResetFile = () => {
    fileRef.current.value = ''
    onFileSelected({ event: null, guid })
  }

  return (
    <div className="file-picker">
      <div>
        {fileTypes[fileType] !== '.pdf' && (
          <div>
            <p>Upload Profile Image</p>
            <div className={`img-container ${imageUrl ? 'selected' : null}`}>
              {imageUrl ? (
                <img src={imageUrl} alt="Profile" />
              ) : (
                <GalleryIcon />
              )}
            </div>
          </div>
        )}

        <div className="button-container">
          <button
            type="button"
            className={`outline ${imageOrFilesClass} ${
              imageOrIconColor ? 'grey-out' : ''
            }`}
          >
            <UploadIcon
              fill={imageOrIconColor ? '#BCBEC0' : iconColor || '#FFFFFF'}
            />
            {buttonLabel}
            <input
              type="file"
              onChange={(event) => onFileSelected({ event, guid })}
              accept={`${fileTypes[fileType]}`}
              ref={fileRef}
            />
          </button>
          {(fileRef.current?.value || fileMetadata) && (
            <button
              className={`outline ${imageOrFilesClass}`}
              type="button"
              onClick={onResetFile}
            >
              {fileTypes[fileType] === '.pdf' ||
              fileTypes[fileMetadata.type] === '.pdf' ? (
                <>
                  <DeleteIcon fill={iconColor || ''} />
                  Delete Attached PDF
                </>
              ) : (
                <>
                  <ResetIcon fill={iconColor || ''} />
                  Reset to Default
                </>
              )}
            </button>
          )}
        </div>
      </div>
      {fileTypes[fileType] === '.pdf' && fileName !== null && <p>{fileName}</p>}
    </div>
  )
}
