export const mediumTime = new Intl.DateTimeFormat('en', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
})

export const ROLE_COMPANY_ADMIN = 'CompanyAdmin'
export const ROLE_COMPANY_SUPERVISOR = 'CompanySupervisor'
export const ROLE_SUPER_USER = 'SuperUser'

export const Roles = {
  SuperUser: 'Super User',
  CompanyAdmin: 'Admin',
  CompanySupervisor: 'Supervisor',
  TradesPerson: 'Trades Person',
}
