import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { PageTitle } from 'context/PageTitleContext'
import { EditIcon } from '../../components/Icons'
import { getClientById } from 'services/clients.svc'
import { CompanyDetails, EmployeeList } from 'components'
import './Company.scss'

export default function CompanyProfile() {
  const { companyId } = useParams()
  const { data } = useQuery(['company', companyId], () =>
    getClientById(companyId),
  )

  return (
    <div className="company-profile">
      <PageTitle title="Company Profile" />
      {data ? (
        <>
          <CompanyDetails data={data} companyId={companyId}>
            <EmployeeList companyId={companyId} />
          </CompanyDetails>
          <Link to={'edit'} className="outline" role="button">
            <EditIcon fill="var(--color-black)" />
            Edit Information
          </Link>
        </>
      ) : (
        <article aria-busy="true">Loading, Please Wait</article>
      )}
    </div>
  )
}
