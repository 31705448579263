import { validateProject } from '@paramount-prestart/shared'
import {
  PLACEHOLDER_POSTCODE,
  PLACEHOLDER_STREET,
  PLACEHOLDER_SUBURB,
} from '@paramount-prestart/shared/placeholders'
import { toList } from '@paramount-prestart/shared/ppeEnum'
import { FilePicker } from 'components'
import PPE from 'components/ppe'
import { useFileUploadAndSave } from 'hooks/useFileUploadAndSave'
import { useMemo, useState } from 'react'
import './ProjectSiteForm.scss'

const ppeList = toList()

const ProjectSiteForm = ({ initialState, mode, employees, submitMutation }) => {
  const [projectState, setProjectState] = useState({ ...initialState })
  const [selectedFile, setSelectedFile] = useState(undefined)

  const supervisors = useMemo(() => {
    if (!employees) {
      return []
    }
    return employees
      .filter((f) => f.userRole.role === 'CompanySupervisor')
      .map((m) => ({ ...m, name: [m.firstName, m.surname].join(' ') }))
  }, [employees])

  const selectedSupervisor = useMemo(() => {
    if (!supervisors) {
      return ''
    }
    const supervisor = supervisors?.find(
      (f) => f.userRole._id === projectState.supervisor,
    )
    return supervisor
  }, [projectState.supervisor, supervisors])

  const errors = useMemo(() => validateProject(projectState), [projectState])

  const canSubmit = useMemo(() => {
    const dontValidate = ['siteSafetyPlan', 'supervisor', 'ppe', 'otherPpe']
    const hasNulls = Object.keys(projectState)
      .filter((f) => !dontValidate.includes(f))
      .some((x) => projectState[x] === null)
    const hasErrors = Object.keys(errors || {}).length > 0
    return !hasNulls && !hasErrors
  }, [errors])

  const submitText = useMemo(() => (mode === 'add' ? 'Finish' : 'Save Updates'))

  const handleInput = ({
    e: {
      target: { name, value },
    },
  }) => {
    setProjectState({ ...projectState, [name]: value })
  }

  const ppeChanged = (val) => {
    const ppe = projectState?.ppe || []
    if (ppe.includes(val)) {
      setProjectState((state) => ({
        ...state,
        ppe: ppe.filter((f) => f !== val),
      }))
    } else {
      setProjectState((state) => ({ ...state, ppe: [...ppe, val] }))
    }
  }

  const handleDropdown = (supervisorId) => {
    setProjectState({ ...projectState, supervisor: supervisorId || null })
    document.getElementById('dropdown').removeAttribute('open')
  }

  const onFileSelected = ({ event, guid }) => {
    if ((!event?.target?.files?.length && !selectedFile) || !event) {
      setSelectedFile(undefined)
      setProjectState({ ...projectState, siteSafetyPlan: null })
    } else if (event?.target?.files?.length) {
      const fileData = event.target.files[0]
      const file = {
        friendlyName: fileData.name,
        fileKey: `${guid}.${fileData.name.split('.').pop()}`,
        type: 'siteSafetyPlan',
      }
      setProjectState({ ...projectState, siteSafetyPlan: file })
      setSelectedFile(fileData)
    }
  }

  const saveMutation = useFileUploadAndSave(
    selectedFile,
    projectState.siteSafetyPlan,
    () => {
      console.log('save project', projectState)
      return submitMutation.mutate(projectState)
    },
  )

  return (
    <div className="project-site-form">
      <div className="inputs">
        <label> Street Address</label>
        <input
          type="text"
          name="address"
          onChange={(e) => handleInput({ e })}
          aria-invalid={
            projectState.address !== null ? Boolean(errors.address) : 'none'
          }
          value={projectState?.address}
          placeholder={PLACEHOLDER_STREET}
          required
        />
        <small>{errors?.address}</small>
        <label>Suburb </label>
        <input
          type="text"
          name="suburb"
          onChange={(e) => handleInput({ e })}
          aria-invalid={
            projectState.suburb !== null ? Boolean(errors.suburb) : 'none'
          }
          value={projectState?.suburb}
          placeholder={PLACEHOLDER_SUBURB}
          required
        />
        <small>{errors?.suburb}</small>
        <label>Postcode </label>
        <input
          type="text"
          name="postcode"
          onChange={(e) => handleInput({ e })}
          aria-invalid={
            projectState.postcode !== null ? Boolean(errors.postcode) : 'none'
          }
          placeholder={PLACEHOLDER_POSTCODE}
          value={projectState?.postcode}
          required
        />
        <small>{errors?.postcode}</small>
        <label>Job No. </label>
        <input
          type="text"
          name="siteNumber"
          onChange={(e) => handleInput({ e })}
          aria-invalid={
            projectState.siteNumber !== null
              ? Boolean(errors.siteNumber)
              : 'none'
          }
          value={projectState?.siteNumber}
        />
        <small>{errors?.siteNumber}</small>
        <label>Client</label>
        <input
          type="text"
          name="clientName"
          onChange={(e) => handleInput({ e })}
          aria-invalid={
            projectState.clientName !== null
              ? Boolean(errors.clientName)
              : 'none'
          }
          value={projectState?.clientName}
        />
        <small>{errors?.clientName}</small>
        <label>Site Supervisor </label>
        <details role="list" id="dropdown">
          <summary aria-haspopup="listbox">
            {' '}
            {selectedSupervisor?.name || 'Unallocated'}
          </summary>
          <ul role="listbox">
            <li onClick={() => handleDropdown('')}>
              <a>Unallocated</a>
            </li>
            {supervisors.map((option, i) => (
              <li
                key={option.userRole._id}
                onClick={() => handleDropdown(option.userRole._id)}
              >
                <a>{option.name}</a>
              </li>
            ))}
          </ul>
        </details>
        <label>Site Supervisors Phone Number </label>
        <input
          key={selectedSupervisor?._id}
          type="text"
          name="supervisorPhone"
          value={selectedSupervisor?.phone}
          readOnly
        />
        <label>Site Safety Plan PDF</label>
        <FilePicker
          fileMetadata={projectState?.siteSafetyPlan}
          selectedFile={selectedFile}
          onFileSelected={onFileSelected}
          fileType="siteSafetyPlan"
          buttonLabel="Upload New PDF"
        />
        <button
          disabled={!canSubmit}
          onClick={() => saveMutation.mutate()}
          className="primary"
          type="submit"
          aria-busy={submitMutation.isLoading || saveMutation.isLoading}
        >
          {submitMutation.isLoading || saveMutation.isLoading
            ? 'Please wait'
            : submitText}
        </button>
      </div>
      <div className="ppe-section">
        <label>PPE Required?</label>
        <div className="icon-container">
          {ppeList.map((ppe) => (
            <div
              key={ppe}
              onClick={() => ppeChanged(ppe)}
              className={projectState?.ppe?.includes(ppe) ? 'selected' : ''}
            >
              <PPE icon={ppe} />
            </div>
          ))}
        </div>
        <label>Other PPE?</label>
        <textarea
          type="text"
          name="otherPpe"
          value={projectState?.otherPpe || ''}
          onChange={(e) => handleInput({ e })}
        />
      </div>
    </div>
  )
}

export default ProjectSiteForm
