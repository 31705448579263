import React from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { PageTitle } from '../../../context/PageTitleContext'
import { getMyProfile } from 'services/clients.svc'
import './ViewProfile.scss'
import { EditIcon } from 'components/Icons'
import { Roles } from 'common/constants'

function ViewProfile() {
  const { data } = useQuery(['user'], getMyProfile)

  return (
    <div className="body-container">
      <PageTitle title="My Profile" />
      {data ? (
        <div className="profile-container">
          <div className={data?.role !== 'SuperUser' ? 'grid-container' : ''}>
            <div>
              <h3>Personal Details</h3>
              <label>First Name</label>
              <p>{data?.firstName || ''}</p>
              <label>Surname</label>
              <p>{data?.surname || ''}</p>
              <label>Phone Number</label>
              <p>{data?.phone || ''}</p>
              <label>Email Address</label>
              <p>{data?.contactEmail || ''}</p>
            </div>
            {data?.role !== 'SuperUser' && (
              <div>
                <h3>Business Details</h3>
                <label>Employer</label>
                <p>
                  {data?.companyDetails?.image?.url ? (
                    <img
                      src={data?.companyDetails?.image?.url}
                      alt="companyLogo"
                    />
                  ) : (
                    []
                  )}

                  {data?.companyDetails?.name}
                </p>
                <label>Role</label>
                <p>{Roles[data?.role]}</p>
              </div>
            )}
          </div>

          <Link role="button" className="outline" to={`/profile/${data?._id}`}>
            <EditIcon width={14} height={14} fill={'var(--font-color)'} />
            Edit Information
          </Link>
        </div>
      ) : (
        <article aria-busy="true">Loading, Please Wait</article>
      )}
    </div>
  )
}

export default ViewProfile
