import React, { useMemo } from 'react'
import { getSwmsForCompany, swmsBulkUpdateById } from 'services/clients.svc'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useParams, Link } from 'react-router-dom'
import {
  MeatBallMenu,
  TableActions,
  FilterDropDown,
  EmptyList,
} from 'components'
import { mediumTime } from 'common/constants'
import { PageTitle } from 'context/PageTitleContext'
import { useTableActions } from 'hooks/useTableActions'
import { RightArrowIcon } from 'components/Icons'
import { toast } from 'react-toastify'
import {
  sortingSWMSByName,
  sortingSWMSByDate,
  sortingSWMSByStatus,
  sortingSWMSByNumber,
  swmsStatusOptions,
} from './constants'
import './SWMS.scss'
import { useParamountAuth } from 'context/ParamountAuthContext'

export default function SWMS() {
  const { companyId } = useParams()
  const { userRoles } = useParamountAuth()
  const queryClient = useQueryClient()
  const { data } = useQuery(['swms', companyId], () =>
    getSwmsForCompany(companyId),
  )

  const getStructuredCompanySwms = (list) => {
    const reviewedAt = (m) =>
      userRoles[0] === 'CompanyAdmin'
        ? Boolean(m.companyAdminReviewedAt)
        : Boolean(m.supervisorReviewedAt)
    return list?.map((m) => ({
      ...m,
      fullName: `${m.createdUserDetails?.firstName} ${m.createdUserDetails?.surname}`,
      siteNumber: m.site.siteNumber,
      siteAddress: m.site.address,
      companyAdminReviewedAt: m.companyAdminReviewedAt,
      companyAdminReviewedBy: m.companyAdminReviewedBy,
      supervisorReviewedAt: m.supervisorReviewedAt,
      supervisorReviewedBy: m.supervisorReviewedBy,
      reviewedAt: reviewedAt(m),
    }))
  }

  const {
    list,
    tableActions: { search, sort, filter, selectedItemIds },
    allChecked,
    setSearch,
    setFilter,
    setSort,
    setSelectedItemIds,
    toggleAllChecked,
    toggleListItemSelected,
  } = useTableActions(getStructuredCompanySwms(data))

  const statusCheck = (reviewed) => {
    return (
      list.filter((swms) => swms?.reviewedAt === reviewed).length ===
        list.length && true
    )
  }

  const disableStatusActions = useMemo(() => {
    const checkBoxes = {
      reviewed: statusCheck(true),
      forReview: statusCheck(false),
    }
    return selectedItemIds.length
      ? checkBoxes
      : { reviewed: true, forReview: true }
  }, [list, selectedItemIds])

  const handleStatusDropDown = (status, e) => {
    updateSwmsMutation.mutate(status)
    e.target.checked = false
  }

  const updateSwmsMutation = useMutation(
    (status) => swmsBulkUpdateById(companyId, selectedItemIds, status),
    {
      onSuccess: (data) => {
        setSelectedItemIds([])
        const structuredData = getStructuredCompanySwms(data)
        queryClient.setQueryData(['swms', companyId], structuredData)
        toast.success('Status updated succesfully')
      },
      onError: (err) => {
        console.error('Failed to update', err)
        toast.error('Status updated failed')
      },
    },
  )

  return (
    <div className="swms-container">
      <PageTitle title="SWMS" />
      <TableActions
        title={'SWMS'}
        searchTerm={search}
        onSearchTermChanged={(e) => setSearch(e.target.value)}
      >
        <span>
          <MeatBallMenu
            menuOptions={swmsStatusOptions}
            onSelected={handleStatusDropDown}
            disabled={disableStatusActions}
          />
        </span>
        <input
          type="checkbox"
          name="checkAll"
          checked={allChecked}
          onClick={() => toggleAllChecked()}
          onChange={() => {}}
        />
      </TableActions>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th scope="col">
                Submitted By
                <FilterDropDown
                  menuItems={sortingSWMSByName}
                  onItemChanged={(sort) => setSort(sort)}
                  selectedItem={sort}
                />
              </th>
              <th scope="col">Site Address</th>
              <th scope="col">
                Job No
                <FilterDropDown
                  menuItems={sortingSWMSByNumber}
                  onItemChanged={(sort) => setSort(sort)}
                  selectedItem={sort}
                />
              </th>
              <th scope="col">
                Data/Time Submitted
                <FilterDropDown
                  menuItems={sortingSWMSByDate}
                  onItemChanged={(sort) => setSort(sort)}
                  selectedItem={sort}
                />
              </th>
              <th>
                Status
                <FilterDropDown
                  menuItems={sortingSWMSByStatus}
                  onItemChanged={(filter) => setFilter(filter)}
                  selectedItem={filter}
                />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {list?.map((swms, i) => (
              <tr key={i}>
                <td>{swms.fullName}</td>
                <td>{swms.site.fullAddress}</td>
                <td>{swms.siteNumber}</td>
                <td>{mediumTime.format(Date.parse(swms.createdAt))}</td>
                <td className="">
                  {' '}
                  <span
                    className={`status-tag ${
                      swms.reviewedAt ? 'reviewed' : 'review'
                    }`}
                  >
                    {swms.reviewedAt ? 'Reviewed' : 'For Review'}{' '}
                  </span>
                </td>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedItemIds?.includes(swms._id)}
                    onChange={() => toggleListItemSelected(swms._id)}
                  />
                  <Link to={`${swms._id}`}>
                    <RightArrowIcon
                      width="10"
                      height="14"
                      fill={'var(--font-color)'}
                    />
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {list.length ? null : <EmptyList />}
    </div>
  )
}
