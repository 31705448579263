import { PageTitle } from 'context/PageTitleContext'
import React from 'react'
import { useParams } from 'react-router-dom'
import { BackButton, InviteUser } from 'components'
import './Add.scss'

export default function CreateSuperVisor({ role }) {
  const { companyId } = useParams()

  return (
    <div className="create-supervisor">
      <PageTitle title="Company Profile" />
      <BackButton />
      <InviteUser
        companyId={companyId}
        role={role}
        title={'Create Site Supervisor User'}
      />
    </div>
  )
}
