import './TableActions.scss'
export default function TableActions({
  title,
  searchTerm,
  onSearchTermChanged,
  children,
}) {
  return (
    <div className="table-action">
      <h3>{title}</h3>
      <div>
        <label>Search:</label>
        <input
          type="text"
          value={searchTerm}
          placeholder="Type Keyword"
          autoComplete="off"
          onChange={(e) => onSearchTermChanged(e)}
        />
        {children}
      </div>
    </div>
  )
}
