import {
  ProfileIcon,
  GroupIcon,
  SWMSIcon,
  ProjectIcon,
  CompanyProfileIcon,
} from 'components/Icons'
import { useParamountAuth } from 'context/ParamountAuthContext'
import {
  ROLE_COMPANY_ADMIN,
  ROLE_COMPANY_SUPERVISOR,
  ROLE_SUPER_USER,
} from '../common/constants'

export const useNavBarItem = () => {
  const { userCompanyId } = useParamountAuth()
  const navItems = [
    {
      page: '/clients',
      name: 'Clients',
      slug: 'clients',
      icon: GroupIcon,
      allowedRoles: [ROLE_SUPER_USER],
    },
    {
      page: `/company/${userCompanyId}/projectsites`,
      name: 'Projects',
      slug: 'projectsites',
      icon: ProjectIcon,
      allowedRoles: [ROLE_COMPANY_ADMIN],
    },
    {
      page: `/company/${userCompanyId}/swms`,
      name: 'SWMS',
      slug: 'swms',
      icon: SWMSIcon,
      allowedRoles: [ROLE_COMPANY_ADMIN, ROLE_COMPANY_SUPERVISOR],
    },
    {
      page: `/company/${userCompanyId}/employees`,
      name: 'Employees',
      slug: 'employees',
      icon: GroupIcon,
      allowedRoles: [ROLE_COMPANY_ADMIN],
    },
    {
      page: '/profile',
      name: 'My Profile',
      slug: 'profile',
      icon: ProfileIcon,
      allowedRoles: [
        ROLE_COMPANY_ADMIN,
        ROLE_COMPANY_SUPERVISOR,
        ROLE_SUPER_USER,
      ],
    },
    {
      page: `/company/${userCompanyId}`,
      name: 'Company Profile',
      slug: 'companyProfile',
      icon: CompanyProfileIcon,
      allowedRoles: [ROLE_COMPANY_ADMIN],
    },
  ]
  return navItems
}
