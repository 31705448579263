import * as PpeEnum from './ppeEnum.js'
import * as ProjectSiteStatusEnum from './projectSiteStatusEnum.js'
import * as _Placeholders from './placeholders.js'

export const PPE_Enum = PpeEnum
export const ProjectSiteStatus_Enum = ProjectSiteStatusEnum
export const Placeholders = _Placeholders

export const FOO = "FOO"
const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9])+$/
const phoneRegex = /^[0-9]{8,10}$/

export const newClient = () => ({
  name: null,
  abn: null,
  buildersRegNumber: null,
  phone: null,
  email: null,
  address: null,
  postalAddress: null,
  image: null,
})

export const newUserInvite = () => ({
  firstName: null,
  surname: null,
  contactEmail: null,
  phone: null,
})

export const newProject = () => ({
  address: null,
  suburb: null,
  postcode: null,
  siteNumber: null,
  clientName: null,
  supervisor: null,
  siteSafetyPlan: null,
  ppe: [],
  otherPpe: null
})

const ABNValidator = (ABN) => {
  const weightingFactor = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19]
  const ABNarray = ABN?.toString().replace(/\s/g, '').split('')
  const ABNWeight = ABNarray?.reduce((prevTotal, currentNum, currentIndex) => {
    const digitWeight = (currentIndex === 0 ? currentNum - 1 : currentNum) * weightingFactor[currentIndex]
    return prevTotal + digitWeight
  }, 0)
  return (ABNWeight % 89) === 0
}

export const validateClient = (client) => {
  let validation = {}
  Object.keys(client).forEach((key) => {
    if (client[key] === '') {
      validation = { ...validation, [key]: `This field is required` }
    }
    if (client[key] && key === 'email' && !emailRegex.test(client[key])) {
      validation = { ...validation, [key]: `A valid email is required` }
    }
    else if (client[key] && key === 'phone' && !phoneRegex.test(client[key])) {
      validation = { ...validation, [key]: `A valid Phone Number is required` }
    }
    else if (client[key] && key === 'abn' && !ABNValidator(client[key])) {
      validation = { ...validation, [key]: `A valid ABN is required` }
    }
  })
  return validation
}

export const validateTaskMobile = (client) => {
  let validation = {}
  const optionalFields = ['abn', 'riskText', 'controlText', 'riskPhoto', 'controlPhoto', 'otherPpe']
  Object.keys(client).forEach((key) => {
    if (typeof client[key] === 'string' && !client[key].trim() && !optionalFields.includes(key)) {
      validation = { ...validation, [key]: `This field is required` }
    }
    if (client[key] && key === 'contactEmail' && !emailRegex.test(client[key])) {
      validation = { ...validation, [key]: `A valid email is required` }
    }
    else if (client[key] && (key === 'emergencyContactPhone' || key === 'phone') && !phoneRegex.test(client[key])) {
      validation = { ...validation, [key]: `A valid Phone Number is required` }
    }
    else if (client[key] && key === 'abn' && !ABNValidator(client[key])) {
      validation = { ...validation, [key]: `A valid ABN is required` }
    }
  })
  return validation
}

export const validateUserInvite = (userInvite) => {
  let validation = {}
  Object.keys(userInvite).forEach((key) => {
    if (userInvite[key] === '') {
      validation = { ...validation, [key]: `This field is required` }
    }
    else if (userInvite[key] && key === 'contactEmail' && !emailRegex.test(userInvite[key])) {
      validation = { ...validation, [key]: `A valid Contact Email is required` }
    }
    else if (userInvite[key] && key === 'phone' && !phoneRegex.test(userInvite[key])) {
      validation = { ...validation, [key]: `A valid Phone Number is required` }
    }
  })
  return validation
}

export const validateProject = (project) => {
  let validation = {}
  Object.keys(project).forEach((key) => {
    if (!['siteSafetyPlan', 'supervisor', 'otherPpe', 'ppe'].includes(key) && project[key] === '') {
      validation = { ...validation, [key]: `This field is required` }
    }
  })
  return validation
}

export const validateEditProfile = (editProfile) => {
  let validation = {}
  Object.keys(editProfile).forEach((key) => {
    if ( editProfile[key] === '') {
      validation = { ...validation, [key]: `This field is required` }
    } else if (key === 'contactEmail' && !emailRegex.test(editProfile[key])) {
      validation = { ...validation, [key]: `A valid email is required` }
    } else if (key === 'phone' && isNaN(editProfile[key])) {
      validation = { ...validation, [key]: `A valid phone number is required` }
    }
  })
  return validation
}

export const fileTypes = {
  photo: ".png,.jpg",
  companyProfile: ".png,.jpg",
  siteSafetyPlan: ".pdf",
  termsConditions: ".pdf",
}

export const newTask = () => ({
  taskDescription: '',
  mobilePlantTools: '',
  riskPhoto: null,
  controlPhoto: null,
  riskText: '',
  controlText: '',
  ppe: []
})

export const newBusinessDetails = () => ({
  businessName: '',
  abn: '',
  tradeQualifications: '',
  whitecardNumber: '',
})

export const newPersonalDetails = () => ({
  firstName: '',
  surname: '',
  phone: '',
  contactEmail: '',
  emergencyContactName: '',
  emergencyContactPhone: ''
})