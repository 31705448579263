import React from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { acceptInvitation, getInvitationDetails } from 'services/clients.svc'
import { useParamountAuth } from 'context/ParamountAuthContext'
import { getInviteCode, removeInviteCode } from 'services/token.svc'
import { Roles } from 'common/constants'
import './AcceptInvite.scss'
import Unauthorised from 'pages/unauthorised/Unauthorised'

function AcceptInvite() {
  const inviteCode = getInviteCode()
  const { data, isError, error } = useQuery(
    ['invitationDetail'],
    () => getInvitationDetails(inviteCode),
    {
      refetchOnWindowFocus: false, // to avoid unwanted loading while switching between tabs.
    },
  )
  const { setAccessToken } = useParamountAuth()
  const navigate = useNavigate()

  const { mutate: onAcceptInvitation } = useMutation(
    () => acceptInvitation({ inviteCode }),
    {
      onSuccess: (data) => {
        removeInviteCode('INVITE_CODE_TOKEN')
        setAccessToken(data.token)
        navigate('/clients')
      },
    },
  )

  if (data?.invitation && !isError) {
    return (
      <div className="accept-invitation">
        <div>
          <img src="./paramountLogo.png" alt="paramount logo" />
        </div>
        <h3>{`You have received an invitation from company ${
          data?.invitation.company.name
        } as ${Roles[data?.invitation?.role]}`}</h3>
        <button type="submit" onClick={onAcceptInvitation}>
          <h6>Accept Invite</h6>
        </button>
      </div>
    )
  } else if (isError) {
    return (
      <Unauthorised
        message={JSON.parse(error?.message)?.message}
        fullScreen={true}
      />
    )
  }

  return <article aria-busy="true">Loading, Please Wait</article>
}

export default AcceptInvite
