import React, { useMemo } from 'react'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { Link, useParams } from 'react-router-dom'
import { getProjectSites, updateProjectSiteStatus } from 'services/clients.svc'
import { PageTitle } from 'context/PageTitleContext'
import { AddIcon, RightArrowIcon } from 'components/Icons'
import {
  FilterDropDown,
  TableActions,
  MeatBallMenu,
  EmptyList,
} from 'components'
import { useTableActions } from 'hooks/useTableActions'
import { toast } from 'react-toastify'
import {
  siteStatusOptions,
  sortingSiteByNumber,
  sortingSiteByClient,
  sortingSiteBySupervisor,
  sortingSiteBySuburb,
  filterSiteByStatus,
  siteStatus,
} from './constants'
import './ProjectSites.scss'

export default function ProjectSites() {
  const { companyId } = useParams()
  const { data } = useQuery(['projectSites'], () => getProjectSites(companyId))
  const queryClient = useQueryClient()

  const {
    list,
    tableActions: { search, sort, filter, selectedItemIds },
    allChecked,
    setSearch,
    setFilter,
    setSort,
    setSelectedItemIds,
    toggleAllChecked,
    toggleListItemSelected,
  } = useTableActions(data)

  const statusCheck = (status) => {
    return (
      list.filter((project) => project?.status === status).length ===
        list.length && true
    )
  }

  const disableStatusActions = useMemo(() => {
    const checkBoxes = {
      ongoing: statusCheck('ongoing'),
      on_hold: statusCheck('on_hold'),
      cancelled: statusCheck('cancelled'),
    }
    return selectedItemIds.length
      ? checkBoxes
      : { ongoing: true, on_hold: true, cancelled: true }
  }, [list, selectedItemIds])

  const handleStatusDropDown = (selectedStatus, e) => {
    updateStatusMutation.mutate(selectedStatus)
    e.target.checked = false
  }

  const updateStatusMutation = useMutation(
    (selectedStatus) =>
      updateProjectSiteStatus({
        companyId,
        data: { projectSiteIds: selectedItemIds, selectedStatus },
      }),
    {
      onSuccess: async (data) => {
        setSelectedItemIds([])
        const responseData = await data?.map((x) => {
          return {
            ...x,
            supervisorName: `${x.supervisorDetails?.firstName} ${x.supervisorDetails?.surname}`,
          }
        })
        queryClient.setQueryData(['projectSites'], responseData)
        toast.success('Status updated successfully')
      },
      onError: async (err) => {
        toast.error('Failed to update the status')
        console.error('Failed to update', err)
      },
    },
  )

  return (
    <div className="project-site">
      <PageTitle title="Projects" />
      <Link to="add" role="button" className="outline">
        <AddIcon fill={'var(--color-black)'} />
        Create New Site Project
      </Link>
      <TableActions
        title={'Project Sites'}
        searchTerm={search}
        onSearchTermChanged={(e) => setSearch(e.target.value)}
      >
        <span>
          <MeatBallMenu
            menuOptions={siteStatusOptions}
            onSelected={handleStatusDropDown}
            disabled={disableStatusActions}
          />
        </span>
        <input
          type="checkbox"
          name="checkAll"
          checked={allChecked}
          onClick={() => toggleAllChecked()}
          onChange={() => {}}
        />
      </TableActions>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th scope="col">Street Address</th>
              <th>
                Suburb
                <FilterDropDown
                  menuItems={sortingSiteBySuburb}
                  onItemChanged={(filterOption) => setSort(filterOption)}
                  selectedItem={sort}
                />
              </th>
              <th>
                Site Supervisor
                <FilterDropDown
                  menuItems={sortingSiteBySupervisor}
                  onItemChanged={(filterOption) => setSort(filterOption)}
                  selectedItem={sort}
                />
              </th>
              <th>
                Client
                <FilterDropDown
                  menuItems={sortingSiteByClient}
                  onItemChanged={(filterOption) => setSort(filterOption)}
                  selectedItem={sort}
                />
              </th>
              <th>
                Job No.
                <FilterDropDown
                  menuItems={sortingSiteByNumber}
                  onItemChanged={(filterOption) => setSort(filterOption)}
                  selectedItem={sort}
                />
              </th>
              <th>
                Status
                <FilterDropDown
                  menuItems={filterSiteByStatus}
                  onItemChanged={(filterOption) => {
                    setFilter(filterOption)
                  }}
                  selectedItem={filter}
                />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {list?.map((site) => (
              <tr key={site._id}>
                <td>{site?.address}</td>
                <td>{site?.suburb}</td>
                <td>{site?.supervisorName}</td>
                <td>{site?.clientName}</td>
                <td>{site?.siteNumber}</td>
                <td>
                  <p className={site?.status}>{siteStatus[site.status]}</p>
                </td>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedItemIds?.includes(site._id)}
                    onChange={() => toggleListItemSelected(site._id)}
                  />
                  <Link to={`${site._id}`}>
                    <RightArrowIcon fill={'var(--font-color)'} />
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {list.length ? null : <EmptyList />}
    </div>
  )
}
