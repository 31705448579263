import { useQuery } from '@tanstack/react-query'
import { Link, useParams } from 'react-router-dom'
import { DescriptionIcon, DownArrowIcon, QrCodeIcon } from 'components/Icons'
import { getSwmsForSite, getCheckInList } from 'services/clients.svc'
import './SiteActivityList.scss'
import { format } from 'date-fns'

export default function SiteActivityList({ title, type }) {
  const { projectSiteId, companyId } = useParams()
  const service = type === 'SWMS' ? getSwmsForSite : getCheckInList
  const queryKey = type === 'SWMS' ? 'swmsList' : 'checkIns'
  const { data } = useQuery([queryKey, projectSiteId, companyId], () =>
    service(projectSiteId, companyId),
  )

  return (
    <div className="activity-list-container">
      <h5>
        {type === 'SWMS' ? (
          <DescriptionIcon fill="var(--font-color)" />
        ) : (
          <QrCodeIcon fill="var(--color-cyan)" />
        )}
        {title}
      </h5>
      <div>
        {data?.map((activity) => {
          return (
            <label key={activity._id}>
              <p>
                <Link
                  to={`/trades/${
                    type === 'SWMS'
                      ? activity?.createdUserDetails?._id
                      : activity?.checkInUser?._id
                  }`}
                >
                  <u>{activity.userName}</u>
                </Link>{' '}
                {type === 'SWMS' ? (
                  <span>
                    submitted a{' '}
                    <Link to={`/company/${companyId}/swms/${activity?._id}`}>
                      <u>SWMS</u>
                    </Link>
                  </span>
                ) : (
                  'checked in'
                )}{' '}
                {format(new Date(activity.createdAt), 'EEE do MMM yyyy HH:mm')}
              </p>
            </label>
          )
        })}
      </div>
      <p className="tagLine">
        Scroll For more
        <DownArrowIcon />
      </p>
    </div>
  )
}
