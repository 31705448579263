import { Delete, Get, Post, Put } from './http.svc'
import {
  getStructuredCompanySwms,
  getStructuredProfile,
  getStructuredProjectSite,
} from 'common/functions'

export const getMyProfile = () => Get('profile').then(getStructuredProfile)

export const updateMyProfile = (userInfo) =>
  Put('profile', userInfo).then(getStructuredProfile)

export const getClients = () => Get('company')

export const getClientById = (clientId) => Get(`company/${clientId}`)

export const addClient = (clientObj) => Post('company', clientObj)

export const deleteClient = (clientId) => Delete(`company/${clientId}`)

export const updateClient = (clientId, clientObj) =>
  Put(`company/${clientId}`, clientObj)

export const getClientEmployees = (clientId) =>
  Get(`company/${clientId}/employees`)

export const getEmployeeInvites = async (clientId) =>
  Get(`company/${clientId}/employees/invite`)

export const getSWMSById = (companyId, swmsId) =>
  Get(`company/${companyId}/swms/${swmsId}`)

export const getSwmsForCompany = (companyId) =>
  Get(`company/${companyId}/swms`).then(getStructuredCompanySwms)

export const swmsBulkUpdateById = (companyId, swmsId, status) =>
  Put(`company/${companyId}/swms`, { swmsId, status })

export const getProjectSites = async (companyId) =>
  Get(`company/${companyId}/projectSites`).then((data) => {
    return data?.map((x) => {
      return {
        ...x,
        supervisorName: x.supervisorDetails
          ? `${x.supervisorDetails?.firstName} ${x.supervisorDetails?.surname}`
          : '',
      }
    })
  })

export const addProject = (companyId, projectObj) =>
  Post(`company/${companyId}/projectSites`, {
    ...projectObj,
    company: companyId,
  })

export const updateProjectSiteStatus = ({ companyId, data }) =>
  Put(`company/${companyId}/projectSites`, data)

export const updateProject = (companyId, projectSiteId, projectObj) =>
  Put(`company/${companyId}/projectSites/${projectSiteId}`, {
    ...projectObj,
    company: companyId,
  })

export const getCompanyEmployees = async (companyId) => {
  return Get(`company/${companyId}/employees`).then((res) =>
    res.map((m) => ({
      ...m,
      fullName: `${m.firstName} ${m.surname}`,
      role: m?.userRole?.role,
    })),
  )
}

export const getCompanyEmployeeDetail = (companyId, employeeId) =>
  Get(`company/${companyId}/employees/${employeeId}`)

export const getTradespersonDetails = (id) => Get(`profile/trades/${id}`)

export const inviteUser = (inviteObj) => Post('user/invite', inviteObj)

export const sendInvite = (userId) => Post(`user/invite/${userId}`)

export const getInvitationDetails = (inviteCode) =>
  Get(`user/invite/${inviteCode}`)

export const acceptInvitation = (acceptInviteOBJ) =>
  Post('user/accept', acceptInviteOBJ)

export const getProjectById = async (projectSiteId, companyId) =>
  Get(`company/${companyId}/projectSites/${projectSiteId}`).then(
    getStructuredProjectSite,
  )

export const getSwmsForSite = (siteId, companyId) => {
  return Get(`company/${companyId}/projectSites/${siteId}/swms`).then((res) =>
    res.map((m) => ({
      ...m,
      userName: `${m.createdUserDetails.firstName} ${m.createdUserDetails.surname}`,
    })),
  )
}

export const getCheckInList = (siteId, companyId) => {
  return Get(`company/${companyId}/projectSites/${siteId}/checkins`).then(
    (res) =>
      res.map((checkIn) => ({
        ...checkIn,
        userName: `${checkIn.checkInUser.firstName} ${checkIn.checkInUser.surname}`,
      })),
  )
}

export const getSasTokenForFile = (filename, type) => {
  return Post('file', { type, filename })
}
