import { useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'
import { PageTitle } from 'context/PageTitleContext'
import { useQuery } from '@tanstack/react-query'
import { BackButton } from 'components'
import { EditIcon, InsertDriveFileIcon, UserIcon } from 'components/Icons'
import { getProjectById, getClientEmployees } from 'services/clients.svc'
import { siteStatus } from '../constants'
import { SiteActivityList } from '../components'
import './Details.scss'
import PPE from 'components/ppe'

const Details = () => {
  const { projectSiteId, companyId } = useParams()
  const { data: siteDetails } = useQuery(
    ['projectSiteDetails', projectSiteId, companyId],
    () => getProjectById(projectSiteId, companyId),
  )
  const { data: companyEmployees } = useQuery(['clientEmployees'], () =>
    getClientEmployees(companyId),
  )

  const supervisorDetails = useMemo(() => {
    if (companyEmployees?.length)
      return companyEmployees.find((f) => {
        return f.userRole._id === siteDetails?.supervisor
      })
    return {}
  }, [companyEmployees, siteDetails])

  return (
    <div className="site-details">
      <PageTitle title="Projects" />
      <BackButton />
      {siteDetails ? (
        <>
          <div className="grid">
            <div className="details-panel">
              <h3>Site Information</h3>
              <div>
                <label>Street Address</label>
                <p>{siteDetails?.address || ''}</p>
                <label>Suburb</label>
                <p>{siteDetails?.suburb || ''}</p>
                <label>Postcode</label>
                <p>{siteDetails?.postcode || ''}</p>
                <label>Job No.</label>
                <p>{siteDetails?.siteNumber || ''}</p>
                <label>Client</label>
                <p>{siteDetails?.clientName || ''}</p>
                <label>Site Supervisor</label>
                <Link
                  to={`/company/${companyId}/employees/${supervisorDetails?._id}`}
                  className="site-supervisor"
                >
                  <p>
                    {supervisorDetails
                      ? [supervisorDetails.firstName, supervisorDetails.surname]
                          .filter(Boolean)
                          .join(' ')
                      : 'Unallocated'}
                  </p>
                  {(supervisorDetails?.firstName ||
                    supervisorDetails?.surname) && (
                    <UserIcon fill={'var(--font-color)'} />
                  )}
                </Link>
                <label>Site Supervisors Phone Number</label>
                <p>
                  {supervisorDetails?.phone || siteDetails.company?.phone || ''}
                </p>
                <label>Status</label>
                <p
                  className={`status-tag ${
                    siteDetails?.status ? siteDetails?.status : ''
                  }`}
                >
                  {siteDetails?.status ? siteStatus[siteDetails?.status] : ''}
                </p>
                <label>Site Safety Plan PDF</label>
                {siteDetails?.siteSafetyPlan?.url ? (
                  <a
                    href={
                      siteDetails?.siteSafetyPlan?.url
                        ? siteDetails?.siteSafetyPlan?.url
                        : ''
                    }
                    rel="noreferrer"
                    target="_blank"
                    role="button"
                    className="contrast view-file"
                  >
                    <InsertDriveFileIcon />
                    View Attached
                  </a>
                ) : (
                  <p>No files added</p>
                )}
              </div>
            </div>
            <div className="project-site-panel">
              <SiteActivityList title={'Latest Check-Ins'} type={'Check-Ins'} />
              <SiteActivityList title={'SWMS Completed'} type={'SWMS'} />
              {siteDetails?.ppe?.length ? (
                <>
                  <label>PPE Required</label>
                  <div className="ppe-container">
                    {siteDetails.ppe.map((m) => (
                      <PPE key={m} icon={m} />
                    ))}
                  </div>
                </>
              ) : (
                []
              )}
              {siteDetails?.otherPpe ? (
                <>
                  <label>Other PPE</label>
                  <p>{siteDetails.otherPpe}</p>
                </>
              ) : (
                []
              )}
            </div>
          </div>
          <Link to={'edit'} role="button" className="outline">
            <EditIcon />
            Edit Information
          </Link>
        </>
      ) : (
        <article aria-busy="true">Loading, Please Wait</article>
      )}
    </div>
  )
}

export default Details
