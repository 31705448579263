import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useMutation, useQuery } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { AddIcon, UserIcon } from 'components/Icons'
import { getClientEmployees, getEmployeeInvites, sendInvite } from 'services/clients.svc'
import { useParamountAuth } from 'context/ParamountAuthContext'
import { Roles } from 'common/constants'
import './EmployeeList.scss'

export default function EmployeeList({ companyId, children }) {
  const { data } = useQuery(['clientEmployees'], () =>
    getClientEmployees(companyId),
  )

  const { userRoles } = useParamountAuth()

  const { admin, supervisors } = useMemo(() => {
    if(!data?.length) {
      return { admin: [], supervisors: [] }
    }
    return {
      admin: data.filter((f) => f.userRole.role === 'CompanyAdmin'),
      supervisors: data.filter((f) => f.userRole.role === 'CompanySupervisor'),
    }
  }, [data])

  const { data: invitedEmployees = [] } = useQuery(['employeeInvites', companyId], () =>
    getEmployeeInvites(companyId)
  )

  const { mutate: resendInvite } = useMutation(sendInvite, {
    onSuccess: async (data) => {
      toast.success('Invite sent sucessfully')
    },
    onError: async (err) => {
      toast.error('Failed to send Invite')
      console.error('Failed to invite', err)
    }
  })

  return (
    <div className="employee-list">
      <div>
        <h6>Admin</h6>
        {admin?.map((employee) => (
          <Link
            to={`employees/${employee._id}`}
            key={employee._id}
            className="profile-svg"
          >
            <p>
              {employee.firstName} {employee.surname}
            </p>
            <UserIcon />
          </Link>
        ))}
      </div>
      <div>
        <h6>Site Supervisors</h6>
        {supervisors?.map((employee) => (
          <Link
            to={`employees/${employee._id}`}
            key={employee._id}
            className="profile-svg"
          >
            <p>
              {employee.firstName} {employee.surname}
            </p>
            <UserIcon />
          </Link>
        ))}
      </div>
      <div>
        {userRoles[0] === "CompanyAdmin" &&
          <Link className="contrast" to={'employees/add'} role="button">
            <AddIcon fill="var(--color-white)" />
            Add Site Supervisor
          </Link>
        }
      </div>
      <div className='invited-users'>
        <h6>Invited Employees</h6>
        {!invitedEmployees.length
          ?
          <p className='no-invites'>No pending invites</p>
          :
          <>
            <p className='users-wrapper'>
              <b>Username</b>
              <b>Email</b>
              <b>Role</b>
            </p>
            {invitedEmployees.map(({ _id, userRole, firstName, surname, contactEmail }) => (
              <p key={_id} className='users-wrapper'>
                <p>{firstName} {surname} </p>
                <p>{contactEmail}</p>
                <p> {Roles[userRole.role]}</p>
                <span onClick={() => resendInvite(userRole._id)}>
                  Resend
                </span>
              </p>
            ))}
          </>
        }
      </div>
      {children}
    </div>
  )
}
