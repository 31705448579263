export const sortingMenuItem = [
  {
    text: 'Sort Most Recent',
    value: 'createdAt.date.asc',
  },
  {
    text: 'Sort A-Z',
    value: 'name.string.asc',
  },
  {
    text: 'Sort Z-A',
    value: 'name.string.desc',
  },
]
