import React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { PageTitle } from 'context/PageTitleContext'
import { useMutation, useQuery } from '@tanstack/react-query'
import { DeleteIcon, EditIcon } from 'components/Icons'
import { deleteClient, getClientById } from 'services/clients.svc'
import { CompanyDetails, BackButton, EmployeeList } from 'components'
import { toast } from 'react-toastify'
import './Details.scss'

function Footer() {
  const navigate = useNavigate()
  const { clientId } = useParams()

  const deleteClientMutation = useMutation(() => deleteClient(clientId), {
    onSuccess: async () => {
      navigate(`/clients`)
      toast.success('Deleted successfully')
    },
    onError: async (err) => {
      toast.error('Delete failed')
      console.error('Failed to delete client', err)
    },
  })

  return (
    <div className="client-footer">
      <Link role="button" to="edit" className="outline">
        <EditIcon fill={'var(--font-color)'} />
        Edit Information
      </Link>
      <button
        type="button"
        className="secondary"
        onClick={() => deleteClientMutation.mutate()}
        aria-busy={deleteClientMutation.isLoading}
      >
        {deleteClientMutation.isLoading ? (
          'Please wait'
        ) : (
          <>
            <DeleteIcon />
            Delete Account
          </>
        )}
      </button>
    </div>
  )
}

export default function Details() {
  const { clientId } = useParams()
  const { data } = useQuery(['clients', clientId], () =>
    getClientById(clientId),
  )

  return (
    <div className="body-container">
      <PageTitle title="Client Details" />
      <div className="client-details">
        <div>
          <BackButton />
          {data ? (
            <CompanyDetails data={data} companyId={clientId}>
              <EmployeeList companyId={clientId} />
            </CompanyDetails>
          ) : (
            <article aria-busy="true">Loading, Please Wait</article>
          )}
        </div>
        {data ? <Footer /> : null}
      </div>
    </div>
  )
}
