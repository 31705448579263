import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getSWMSById, swmsBulkUpdateById } from 'services/clients.svc'
import { BackButton } from 'components'
import Unauthorised from 'pages/unauthorised/Unauthorised'
import { swmsRiskLikelihood, swmsRiskConsequence } from '../constants'
import './Review.scss'
import { TickIcon, UserIcon } from '../../../../components/Icons'
import { mediumTime } from 'common/constants'
import { getStructuredCompanySwms } from 'common/functions'
import { PageTitle } from 'context/PageTitleContext'
import { useParamountAuth } from 'context/ParamountAuthContext'
import ImagePreview from './ImagePreview'
import PPE from 'components/ppe'

const Review = () => {
  const { companyId, swmsId } = useParams()
  const { data } = useQuery(['user', companyId, swmsId], () =>
    getSWMSById(companyId, swmsId),
  )
  const queryClient = useQueryClient()

  const { userRoles } = useParamountAuth()
  const reviewedAt =
    userRoles[0] === 'CompanyAdmin'
      ? Boolean(data?.companyAdminReviewedAt)
      : Boolean(data?.supervisorReviewedAt)

  const updateStatusMutation = useMutation(
    (status) => swmsBulkUpdateById(companyId, swmsId, status),
    {
      onSuccess: (data) => {
        const constructedData = getStructuredCompanySwms([data])
        queryClient.setQueryData(
          ['user', companyId, swmsId],
          constructedData[0],
        )
        toast.success('Status updated successfully')
      },
      onError: (err) => {
        console.error('Failed to update', err)
        toast.error('Status updated failed')
      },
    },
  )

  const getOverallRiskData = (level) => {
    if (level > 15) {
      return {
        name: 'Catastrophic',
        color: 'var(--color-red-700)',
      }
    } else if (level > 9) {
      return {
        name: 'High',
        color: 'var(--color-orange-600)',
      }
    } else if (level > 3) {
      return {
        name: 'Moderate',
        color: 'var(--color-yellow-500)',
      }
    } else {
      return {
        name: 'Low',
        color: 'var(--color-green-700)',
      }
    }
  }

  return (
    <div className="body-container">
      <PageTitle title="SWMS" />
      <div className="review-container">
        <BackButton />
        <h3>SWMS Submission</h3>
        {data && Object.keys(data).length !== 0 ? (
          <div className="body-container">
            <div>
              <div>
                <label>Submitted by</label>
                <Link to={`/trades/${data?.createdUserDetails?._id}`}>
                  <p>
                    {`${data?.createdUserDetails?.firstName || ''} ${
                      data?.createdUserDetails?.surname || ''
                    }`}
                  </p>{' '}
                  {data?.createdUserDetails && (
                    <UserIcon fill={'var(--font-color)'} />
                  )}
                </Link>
                <label>Site Address</label>
                <p>{data?.site?.fullAddress || ''}</p>
                <label>Job No.</label>
                <p>{data?.site?.siteNumber || ''}</p>
                <label>Date/Time Submitted</label>
                <p>
                  {(data?.createdAt &&
                    mediumTime.format(Date.parse(data?.createdAt))) ||
                    ''}
                </p>
                <label>Status</label>
                <p
                  data-tooltip={
                    data?.companyAdminReviewedAt
                      ? `Reviewed By ${data.companyAdminReviewerDetails?.firstName} ${data.companyAdminReviewerDetails?.surname} @ ${data.companyAdminReviewedAt}`
                      : null
                  }
                >
                  Manager Review :{' '}
                  {data?.companyAdminReviewedAt ? 'Reviewed' : 'For Review'}
                </p>
                <p
                  data-tooltip={
                    data?.supervisorReviewedAt
                      ? `Reviewed By ${data.supervisorReviewerDetails?.firstName} ${data.supervisorReviewerDetails?.surname} @ ${data.supervisorReviewedAt}`
                      : null
                  }
                  data-placement="Right"
                >
                  Supervisor Review :{' '}
                  {data?.supervisorReviewedAt ? 'Reviewed' : 'For Review'}
                </p>
              </div>
              <div>
                <label>High Risk construction Work</label>
                <p>{data?.highRiskWork || ''}</p>
                <label>Risk Consequence Level</label>
                {data?.riskConsequenceLevel ? (
                  <span>
                    <p
                      style={{
                        color:
                          swmsRiskConsequence[data?.riskConsequenceLevel]
                            ?.color,
                      }}
                    >
                      {`${data?.riskConsequenceLevel} - ${
                        swmsRiskConsequence[data?.riskConsequenceLevel]?.name
                      }`}
                    </p>
                    <p className="text-grey">
                      ({swmsRiskConsequence[data?.riskConsequenceLevel]?.value})
                    </p>
                  </span>
                ) : null}
                <label>Risk Likelihood Level</label>
                {data?.riskLikelihoodLevel ? (
                  <span>
                    <p
                      style={{
                        color:
                          swmsRiskLikelihood[data?.riskLikelihoodLevel]?.color,
                      }}
                    >
                      {`${data?.riskLikelihoodLevel} - ${
                        swmsRiskLikelihood[data?.riskLikelihoodLevel]?.name
                      }`}
                    </p>
                    <p className="text-grey">
                      ({swmsRiskLikelihood[data?.riskLikelihoodLevel]?.value})
                    </p>
                  </span>
                ) : null}
                <label>Overall Risk Level</label>
                {data?.riskOverallLevel ? (
                  <span>
                    <p
                      style={{
                        color: getOverallRiskData(data?.riskOverallLevel)
                          ?.color,
                      }}
                    >
                      {`${data?.riskOverallLevel} - ${
                        getOverallRiskData(data?.riskOverallLevel)?.name
                      }`}
                    </p>
                  </span>
                ) : null}
                <hr />
                <label>Task to Complete</label>
                <p>{data?.task?.taskDescription || ''}</p>
                <label>Mobile Plant and Power Tools In Use</label>
                <p>{data?.task?.mobilePlantTools || ''}</p>
                <label>Potential Hazard/Risk</label>
                <p>{data?.task?.riskText || ''}</p>
                <ImagePreview image={data.task.riskPhoto} />
                <label>Hazard Control Measure</label>
                <p>{data?.task?.controlText || ''}</p>
                <ImagePreview image={data.task.controlPhoto} />
              </div>
              <div>
                <label>PPE Required</label>
                <div className="icon-container">
                  {data?.task?.ppe?.map((ppe) => (
                    <div key={ppe}>
                      <PPE icon={ppe} />
                    </div>
                  ))}
                </div>
                {data?.task?.otherPpe ? (
                  <>
                    <label>Other</label>
                    <p>{data.task.otherPpe}</p>
                  </>
                ) : (
                  []
                )}
              </div>
            </div>
            {!reviewedAt && (
              <button
                className="primary"
                onClick={() => updateStatusMutation.mutate('reviewed')}
                aria-busy={updateStatusMutation.isLoading}
              >
                {updateStatusMutation.isLoading ? (
                  'Please wait'
                ) : (
                  <>
                    <TickIcon fill="var(--font-color)" />
                    Check as Reviewed
                  </>
                )}
              </button>
            )}
          </div>
        ) : data ? (
          <Unauthorised message={'You are not authorised to view this SWMS'} />
        ) : (
          <article aria-busy="true">Loading, Please Wait</article>
        )}
      </div>
    </div>
  )
}

export default Review
