import { useMutation } from '@tanstack/react-query'
import { getSasTokenForFile } from 'services/clients.svc'
import { uploadFile } from 'common/functions'

export const useFileUploadAndSave = (file, fileMetadata, saveFn) => {
  const upload = async () => {
    if (file && fileMetadata) {
      const { sasToken } = await getSasTokenForFile(
        fileMetadata?.fileKey,
        fileMetadata?.type,
      )
      await uploadFile(file, sasToken)
    }
    saveFn()
  }
  const uploadMutation = useMutation(() => upload())

  return uploadMutation
}
