import Company from './Company'
import CompanyEdit from './Edit/Edit'
import CompanyAddEmployee from './Employees/Add/Add'
import CompanyEmployeeList from './Employees/List/List'
import CompanyEmployeeDetails from './Employees/Details/Details'

import CompanyProjectSites from './ProjectSites/ProjectSites'
import CompanyProjectSitesAdd from './ProjectSites/Add/Add'
import CompanyProjectSitesDetails from './ProjectSites/Details/Details'
import CompanyProjectSitesEdit from './ProjectSites/Edit/Edit'

import CompanySwmsList from './SWMS/SWMS'
import CompanySwmsReview from './SWMS/Review/Review'

export default {
  Company,
  CompanyEdit,
  CompanyAddEmployee,
  CompanyEmployeeList,
  CompanyEmployeeDetails,
  CompanyProjectSites,
  CompanyProjectSitesAdd,
  CompanyProjectSitesDetails,
  CompanyProjectSitesEdit,
  CompanySwmsList,
  CompanySwmsReview,
}
