import React from 'react'
import { useLocation } from 'react-router-dom'
import './Header.scss'
import { usePageTitle } from '../../context/PageTitleContext'
import { PresetIcon, ParamountIcon } from 'components/Icons'

function Header() {
  const location = useLocation()
  const { title } = usePageTitle()

  if (
    location.pathname === '/' ||
    location.pathname === '/accept_invitation' ||
    location.pathname === '/unauthorised_role'
  ) {
    return null
  }
  return (
    <div className="grid header-container">
      <div>
        <ParamountIcon />
        <PresetIcon />
      </div>
      <h2>{title}</h2>
    </div>
  )
}

export default Header
