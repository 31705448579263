import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './Login.scss'
import { useParamountAuth } from 'context/ParamountAuthContext'
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
} from 'firebase/auth'
import { AppleIcon, GoogleIcon } from 'components/Icons'
import { VersionInfo } from 'components'
import { getInviteCode } from 'services/token.svc'

function Login() {
  const { handleSignIn, initialised, tokenGuts } = useParamountAuth()
  const [signInButtonClicked, setSignInButtonClicked] = useState({
    type: '',
    clicked: false,
  })

  const navigate = useNavigate()
  const isValidInviteToken = getInviteCode()

  const loginSuccess = (roles) => {
    if (isValidInviteToken) {
      navigate('/accept_invitation')
    } else if (!isValidInviteToken && roles.includes('Unregistered')) {
      navigate('/unauthorised_role')
    } else {
      navigate('/clients')
    }
    setSignInButtonClicked({ type: '', clicked: false })
  }

  const googleLogin = () => {
    setSignInButtonClicked({ type: 'google', clicked: true })
    const auth = getAuth()
    const provider = new GoogleAuthProvider()
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const idToken = await auth.currentUser.getIdToken()
        await handleSignIn('google', idToken).then((res) => {
          if (res) {
            const token = tokenGuts(res)
            loginSuccess(token.roles)
          }
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const appleLogin = () => {
    setSignInButtonClicked({ type: 'apple', clicked: true })
    const auth = getAuth()
    const provider = new OAuthProvider('apple.com')
    provider.addScope('email')
    provider.addScope('name')
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const idToken = await auth.currentUser.getIdToken()
        handleSignIn('apple', idToken).then((res) => {
          if (res) {
            const token = tokenGuts(res)
            loginSuccess(token.roles)
          }
        })
      })
      .catch((error) => {
        // Handle Errors here.
        console.error(error)
      })
  }

  return (
    <div className="login-container">
      <img src="./paramountLogo.png" alt="paramount logo" />
      {initialised ? (
        <>
          <button
            onClick={googleLogin}
            aria-busy={
              signInButtonClicked.type === 'google'
                ? signInButtonClicked.clicked
                : false
            }
            disabled={signInButtonClicked.type}
          >
            <GoogleIcon />
            Continue with Google
          </button>
          <button
            onClick={appleLogin}
            aria-busy={
              signInButtonClicked.type === 'apple'
                ? signInButtonClicked.clicked
                : false
            }
            disabled={signInButtonClicked.type}
          >
            <AppleIcon />
            Continue with Apple
          </button>
        </>
      ) : (
        []
      )}
      <VersionInfo />
    </div>
  )
}

export default Login
