/* global Headers */
import { getAccessToken } from './token.svc'

const apiBase = process.env.REACT_APP_API_BASE

const requestOptions = (method, body, tokenOverride) => {
  const headers = new Headers()
  const accessToken = getAccessToken()
  headers.append('Authorization', `Bearer ${tokenOverride || accessToken}`)
  headers.append('Content-Type', `application/json`)
  let options = {
    method,
    headers,
  }
  if (body) {
    options = { ...options, body: JSON.stringify(body) }
  }
  return options
}

const request = async (url, method, body, tokenOverride) => {
  return await fetch(url, requestOptions(method, body, tokenOverride)).then(
    async (response) => {
      if (response.ok) {
        return response.json()
      }
      throw new Error(await response.text())
    },
  )
}

export const Get = (endpoint) => request(`${apiBase}/${endpoint}`)

export const Post = (endpoint, body, tokenOverride) =>
  request(`${apiBase}/${endpoint}`, 'POST', body, tokenOverride)

export const Put = (endpoint, body) =>
  request(`${apiBase}/${endpoint}`, 'PUT', body)

export const Delete = (endpoint) => request(`${apiBase}/${endpoint}`, 'DELETE')
