import React from 'react'
import { MeatBallIcon } from 'components/Icons'
import './MeatBallMenu.scss'

export default function MeatBallMenu({ menuOptions, onSelected, disabled }) {
  return (
    <div className="meat-ball">
      <details role="list">
        <summary>
          <MeatBallIcon fill={'var(--font-color)'} />
        </summary>
        <ul role="listbox">
          <h6>Change Status</h6>
          {menuOptions?.map((item) => (
            <li key={item.text}>
              <input
                type="radio"
                id={item.text}
                name="radio"
                onChange={(e) => onSelected(item.value, e)}
                disabled={disabled[item.value]}
              />
              <label
                className="status-tag"
                style={{ background: `${item.background}` }}
                htmlFor={item.text}
                id={item.text}
                disabled={disabled[item.value]}
              >
                {' '}
                {item.text}{' '}
              </label>
            </li>
          ))}
        </ul>
      </details>
    </div>
  )
}
