import React from 'react'
import { useNavigate } from 'react-router-dom'
import { RightArrowIcon } from 'components/Icons'
import { FilterDropDown, EmptyList } from 'components'
import { sortingMenuItem } from '../constants'
import './ClientTable.scss'

export default function ClientTable({ sortingValue, setSortingValue, data }) {
  const navigate = useNavigate()

  return (
    <div className="client-table table-container">
      <table>
        <thead>
          <tr>
            <th scope="col">
              Company Name
              <FilterDropDown
                menuItems={sortingMenuItem}
                onItemChanged={(sortingValue) => setSortingValue(sortingValue)}
                selectedItem={sortingValue}
              />
            </th>
            <th>ABN</th>
            <th>Office Address</th>
            <th>User Count</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item) => (
            <tr key={item._id} onClick={() => navigate(`${item._id}`)}>
              <td>
                <span>
                  {item?.image?.url ? (
                    <img src={item?.image?.url} alt={item?.image?.url} />
                  ) : (
                    []
                  )}
                </span>
                {item.name}
              </td>
              <td>{item.abn}</td>
              <td>{item.address}</td>
              <td>{item?.userCount}</td>
              <td>
                <RightArrowIcon fill={'var(--font-color)'} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {data.length ? null : <EmptyList />}
    </div>
  )
}
