import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { BackButton } from 'components'
import './TradesPersonDetails.scss'
import { PageTitle } from 'context/PageTitleContext'
import { getTradespersonDetails } from 'services/clients.svc'

const TradesPersonDetails = () => {
  const { id } = useParams()
  const { data } = useQuery(['tradePersonDetails', id], () =>
    getTradespersonDetails(id),
  )

  return (
    <div className="trader-details-container body-container">
      <PageTitle title="Tradesperson Details" />
      <BackButton />
      {data ? (
        <div className={`grid`}>
          <div>
            <h3>Personal Details</h3>
            <label>First Name</label>
            <p>{data?.firstName || ''}</p>
            <label>Surname</label>
            <p>{data?.surname || ''}</p>
            <label>Phone Number</label>
            <p>{data?.phone || ''}</p>
            <label>Email Address</label>
            <p>{data?.contactEmail || ''}</p>
            <label>Emergency Contact</label>
            <p>{data?.emergencyContactName || ''}</p>
            <p>{data?.emergencyContactPhone || ''}</p>
          </div>
          <div>
            <h3>Additional Information</h3>
            <label>Trades/Qualifications</label>
            <p>{data?.businessDetails?.tradeQualifications || ''}</p>
            <label>White Card No.</label>
            <p>{data?.businessDetails?.whitecardNumber || ''}</p>
          </div>
        </div>
      ) : (
        <article aria-busy="true">Loading, Please Wait</article>
      )}
    </div>
  )
}

export default TradesPersonDetails
