import LogoutButton from 'components/buttons/LogoutButton'
import React from 'react'
import './Unauthorised.scss'

const Unauthorised = ({ message, fullScreen = false }) => {
  return (
    <div className={`unauthorised-container ${fullScreen ? 'fullscreen' : ''}`}>
      <h1>{message}</h1>
      {fullScreen && (
        <>
          <br />
          <LogoutButton />
        </>
      )}
    </div>
  )
}

export default Unauthorised
