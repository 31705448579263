import React from 'react'
import {
  Routes,
  Route,
  Outlet,
  Navigate,
  useSearchParams,
  useParams,
} from 'react-router-dom'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { Header, NavBar, ThemeSwitcher } from 'components'
import { Login, clients, AcceptInvite, Unauthorised } from './pages'
import './App.scss'
import company from 'pages/company'
import profile from 'pages/profile'
import { useParamountAuth } from 'context/ParamountAuthContext'
import {
  ROLE_COMPANY_ADMIN,
  ROLE_COMPANY_SUPERVISOR,
  ROLE_SUPER_USER,
} from 'common/constants'
import {
  getAccessToken,
  getInviteCode,
  setInviteCode,
} from './services/token.svc'
import { createBrowserHistory } from 'history'
import TradesPersonDetails from 'pages/profile/ViewProfile/TradesPersonDetails'

function PrivateRoute() {
  const { isLoggedIn } = useParamountAuth()

  if(!isLoggedIn) {
    return <Navigate to={'/'} replace />
  }
  return <Outlet />
}

function SupervisorRoute() {
  const { userRoles, userCompanyId } = useParamountAuth()
  if(userRoles?.includes(ROLE_COMPANY_ADMIN)) {
    return <Outlet />
  } else {
    return <Navigate to={`/company/${userCompanyId}/swms`} replace />
  }
}

function CompanyRoute() {
  const { userRoles, userCompanyId } = useParamountAuth()
  const { companyId } = useParams()

  if(userCompanyId !== companyId) {
    return <Navigate to={'/unauthorised'} replace />
  } else if(
    userRoles?.includes(ROLE_COMPANY_ADMIN) ||
    userRoles?.includes(ROLE_COMPANY_SUPERVISOR)
  ) {
    return <Outlet />
  }
  return <Navigate to={'/clients'} replace />
}

function ClientRoute() {
  const { userRoles, userCompanyId } = useParamountAuth()

  if(userRoles?.includes(ROLE_SUPER_USER)) {
    return <Outlet />
  }
  return <Navigate to={`/company/${userCompanyId}`} replace />
}

function LoginRoute({ children, path }) {
  const { isLoggedIn, userRoles, userCompanyId } = useParamountAuth()

  if(!isLoggedIn || userRoles.includes('Unregistered')) {
    return children
  }
  return (
    <Navigate
      to={path?.pathname !== '/' ? path.pathname : `/company/${userCompanyId}`}
      replace
    />
  )
}

function AcceptInviteRoute({ children }) {
  const { tokenGuts, isLoggedIn } = useParamountAuth()
  const [searchParams] = useSearchParams()
  const isInviteCodeValid = searchParams.get('invitationCode')
  const invitationCode = getInviteCode()
  const accessToken = getAccessToken()
  const tokenReponse = tokenGuts(accessToken)

  if(
    isInviteCodeValid ||
    (!isInviteCodeValid && !getInviteCode() && !isLoggedIn) ||
    (getInviteCode() && !getAccessToken())
  ) {
    setInviteCode(isInviteCodeValid)
    return <Navigate to={`/`} replace />
  } else if(invitationCode && !tokenReponse?.roles?.includes('Unregistered')) {
    return <Navigate to={`/clients`} replace />
  }
  return children
}

function App() {
  const queryClient = new QueryClient()
  const history = createBrowserHistory()
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <Header />
        <div className="app-container">
          <NavBar />
          <Routes>
            <Route
              path="/accept_invitation"
              element={
                <AcceptInviteRoute>
                  <AcceptInvite />
                </AcceptInviteRoute>
              }
            />
            <Route
              path="/"
              element={
                <LoginRoute path={history?.location}>
                  <Login />
                </LoginRoute>
              }
            />
            <Route
              path="/unauthorised_role"
              element={
                <LoginRoute>
                  <Unauthorised
                    message={'You have no roles in the system'}
                    fullScreen={true}
                  />
                </LoginRoute>
              }
            />
            <Route element={<PrivateRoute />}>
              <Route
                path="*"
                element={
                  <Unauthorised
                    message={'You are not authorised to view this page'}
                  />
                }
              />
              <Route path="clients" element={<ClientRoute />}>
                <Route path="" element={<clients.Clients />} />
                <Route path=":clientId" element={<Outlet />}>
                  <Route path="" element={<clients.Details />} />
                  <Route path="edit" element={<clients.ClientEdit />} />
                  <Route path="employees" element={<Outlet />}>
                    <Route
                      path=":employeeId"
                      element={<clients.UserDetails />}
                    />
                    <Route
                      path="add"
                      element={<clients.ClientAddAdmin role="CompanyAdmin" />}
                    />
                  </Route>
                </Route>
                <Route path="create" element={<clients.ClientAdd />} />
              </Route>
              <Route path="/profile" element={<Outlet />}>
                <Route path="" element={<profile.ViewProfile />} />
                <Route path=":profileId" element={<profile.EditProfile />} />
                <Route path="create" element={<clients.ClientAdd />} />
              </Route>
              <Route path="/trades/:id" element={<TradesPersonDetails />} />
              <Route path="company/:companyId" element={<CompanyRoute />}>
                <Route path="" element={<SupervisorRoute />}>
                  <Route path="" element={<company.Company />} />
                  <Route path="edit" element={<company.CompanyEdit />} />
                  <Route path="employees">
                    <Route path="" element={<company.CompanyEmployeeList />} />
                    <Route
                      path=":employeeId"
                      element={<company.CompanyEmployeeDetails />}
                    />
                    <Route
                      path="add"
                      element={
                        <company.CompanyAddEmployee role="CompanySupervisor" />
                      }
                    />
                  </Route>
                  <Route path="projectsites" element={<Outlet />}>
                    <Route path="" element={<company.CompanyProjectSites />} />
                    <Route
                      path="add"
                      element={<company.CompanyProjectSitesAdd />}
                    />
                    <Route
                      path=":projectSiteId"
                      element={<company.CompanyProjectSitesDetails />}
                    />
                    <Route
                      path=":projectSiteId/edit"
                      element={<company.CompanyProjectSitesEdit />}
                    />
                  </Route>
                </Route>
                <Route path="swms" element={<Outlet />}>
                  <Route path="" element={<company.CompanySwmsList />} />
                  <Route
                    path=":swmsId"
                    element={<company.CompanySwmsReview />}
                  />
                </Route>
              </Route>
            </Route>
          </Routes>
        </div>
        <ThemeSwitcher />
      </div>
    </QueryClientProvider>
  )
}

export default App
