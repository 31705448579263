import React, { useCallback, useEffect, useMemo, useState } from 'react'
import './ThemeSwitcher.scss'

const USE_DARK_MODE = 'Use dark mode'
const USE_LIGHT_MODE = 'Use light mode'

const preferedColorScheme = () =>
  window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'

function ThemeSwitcher() {
  const [scheme, setScheme] = useState(preferedColorScheme())
  const buttonTxt = useMemo(
    () => (scheme === 'dark' ? USE_LIGHT_MODE : USE_DARK_MODE),
    [scheme],
  )
  useEffect(() => {
    document.querySelector('html')?.setAttribute('data-theme', scheme)
  }, [scheme])

  const toggle = useCallback(() => {
    const newScheme = scheme === 'dark' ? 'light' : 'dark'
    setScheme(newScheme)
  }, [scheme, setScheme])

  return (
    <button
      tabIndex={-1}
      className="contrast switcher theme-switcher"
      onClick={toggle}
      type="button"
    >
      <i>{buttonTxt}</i>
    </button>
  )
}

export default ThemeSwitcher
