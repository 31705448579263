import { BlockBlobClient } from '@azure/storage-blob'

export const uploadFile = async (file, uploadSasToken) => {
  const blockBlobClient = new BlockBlobClient(uploadSasToken)
  const blobOptions = { blobHTTPHeaders: { blobContentType: file.type } }
  const fileData = await file.arrayBuffer()
  return blockBlobClient.upload(fileData, fileData.byteLength, blobOptions)
}

export const getStructuredCompanySwms = (list) => {
  return list.map((m) => ({
    ...m,
    fullName: `${m.createdUserDetails?.firstName} ${m.createdUserDetails?.surname}`,
    siteNumber: m.site.siteNumber,
    siteAddress: m.site.address,
    companyAdminReviewedAt: m.companyAdminReviewedAt,
    companyAdminReviewedBy: m.companyAdminReviewedBy,
    supervisorReviewedAt: m.supervisorReviewedAt,
    supervisorReviewedBy: m.supervisorReviewedBy,
  }))
}

export const getStructuredProfile = (profileData) => {
  const companyDetails = {
    name: profileData?.companyDetails?.name,
    image: profileData?.companyDetails?.image,
  }

  const profileDetails = {
    _id: profileData?._id,
    firstName: profileData?.firstName,
    surname: profileData?.surname,
    contactEmail: profileData?.contactEmail,
    userId: profileData?.userRole?.user,
    role: profileData?.userRole?.role,
    phone: profileData?.phone,
  }
  return profileData?.userRole?.role === 'SuperUser'
    ? profileDetails
    : profileData?.userRole?.role === 'CompanySupervisor'
    ? {
        ...profileDetails,
        companyDetails,
        businessDetails: profileData.businessDetails,
      }
    : {
        ...profileDetails,
        companyDetails,
      }
}

export const deleteFileIfItExists = async (deleteToken) => {
  const blockBlobClient = new BlockBlobClient(deleteToken)
  return blockBlobClient.deleteIfExists()
}

export const checkActive = (pageName, location) => {
  const pathName = location.pathname.split('/')
  if (pageName === pathName[pathName.length - 2]) {
    return 'active'
  }
  return ''
}

export const getStructuredProjectSite = (data) => {
  return {
    ...data,
    supervisorName: [
      data.supervisorDetails?.firstName,
      data.supervisorDetails?.surname,
    ]
      .filter(Boolean)
      .join(' '),
    supervisorPhone: data.supervisorDetails?.phone || data.company?.phone || '',
  }
}
