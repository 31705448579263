import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { BackButton } from 'components'
import { useParams } from 'react-router-dom'
import { PageTitle } from 'context/PageTitleContext'
import EditClientPage from './EditClientPage'
import { getClientById } from 'services/clients.svc'
import './Edit.scss'

export default function Edit() {
  const { clientId } = useParams()
  const { data } = useQuery(['editClients', clientId], () =>
    getClientById(clientId),
  )

  return (
    <div className="edit-client">
      <PageTitle title="Edit Client" />
      <BackButton />
      {data ? (
        <EditClientPage clientId={clientId} clientDetail={data} />
      ) : (
        <article aria-busy="true">Loading, Please Wait</article>
      )}
    </div>
  )
}
