import React, { useState, useMemo } from 'react'
import { newUserInvite, validateUserInvite } from '@paramount-prestart/shared'
import { inviteUser } from 'services/clients.svc'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import './InviteUser.scss'
import {
  PLACEHOLDER_EMAIL,
  PLACEHOLDER_FIRSTNAME,
  PLACEHOLDER_PHONE,
  PLACEHOLDER_SURNAME,
} from '@paramount-prestart/shared/placeholders'

export default function InviteUser({ companyId, role, title }) {
  const [values, setValues] = useState(newUserInvite)
  const navigate = useNavigate()
  const errors = useMemo(() => validateUserInvite(values), [values])
  const canSubmit = useMemo(() =>
    Object.values(values).findIndex((x) => x === null) === -1
      ? !errors || Object.keys(errors).length < 1
      : false[errors],
  )

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setValues({
      ...values,
      [name]: value,
    })
  }

  const inviteUserMutation = useMutation(
    () =>
      inviteUser({
        firstName: values.firstName,
        surname: values.surname,
        email: values.contactEmail,
        phone: values.phone,
        companyId,
        role,
      }),
    {
      onSuccess: async (data) => {
        toast.success('Invite sent sucessfully')
        navigate(role === 'CompanyAdmin' ? `/clients/${companyId}` : -1)
      },
      onError: async (err) => {
        toast.error('Failed to send Invite')
        console.error('Failed to invite', err)
      },
    },
  )

  return (
    <div className="invite-user">
      <div>
        <h3>{title}</h3>
        <div className="grid">
          <div>
            <label>First Name</label>
            <input
              type="text"
              name={'firstName'}
              autoComplete="off"
              value={values.firstName || ''}
              aria-invalid={
                values.firstName !== null ? Boolean(errors.firstName) : 'none'
              }
              placeholder={PLACEHOLDER_FIRSTNAME}
              onChange={handleInputChange}
            />
            <small>{errors?.firstName}</small>
            <label>Email Address</label>
            <input
              type="text"
              name={'contactEmail'}
              autoComplete="off"
              value={values.contactEmail || ''}
              aria-invalid={
                values.contactEmail !== null
                  ? Boolean(errors.contactEmail)
                  : 'none'
              }
              placeholder={PLACEHOLDER_EMAIL}
              onChange={handleInputChange}
            />
            <small>{errors?.contactEmail}</small>
          </div>
          <div>
            <label>
              Surname
              <input
                type="text"
                name={'surname'}
                autoComplete="off"
                value={values.surname || ''}
                aria-invalid={
                  values.surname !== null ? Boolean(errors.surname) : 'none'
                }
                placeholder={PLACEHOLDER_SURNAME}
                onChange={handleInputChange}
              />
            </label>
            <small>{errors?.surname}</small>
            <label>
              Phone Number
              <input
                type="text"
                name={'phone'}
                autoComplete="off"
                value={values.phone || ''}
                aria-invalid={
                  values.phone !== null ? Boolean(errors.phone) : 'none'
                }
                placeholder={PLACEHOLDER_PHONE}
                onChange={handleInputChange}
              />
            </label>
            <small>{errors?.phone}</small>
          </div>
        </div>
        <button
          className="contrast"
          type="button"
          onClick={() => inviteUserMutation.mutate()}
          disabled={!canSubmit || inviteUserMutation.isLoading}
          aria-busy={inviteUserMutation.isLoading}
        >
          {inviteUserMutation.isLoading ? 'Please wait' : 'Send Invite'}
        </button>
      </div>
    </div>
  )
}
