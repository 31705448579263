import React from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { getClients } from 'services/clients.svc'
import { PageTitle } from 'context/PageTitleContext'
import { useTableActions } from 'hooks/useTableActions'
import { TableActions } from 'components'
import { AddIcon } from 'components/Icons'
import ClientTable from './components/ClientTable'
import './Clients.scss'

export default function Clients() {
  const { data } = useQuery(['clients'], getClients)
  const {
    list,
    tableActions: { search, sort },
    setSearch,
    setSort,
  } = useTableActions(data)

  return (
    <div className="client">
      <PageTitle title="Clients" />
      <Link to="create" className="outline" role="button">
        <AddIcon fill={'var(--font-color)'} />
        Create New Client
      </Link>
      <TableActions
        title={'Client List'}
        searchTerm={search}
        onSearchTermChanged={(e) => setSearch(e.target.value)}
      />
      <ClientTable data={list} setSortingValue={setSort} sortingValue={sort} />
    </div>
  )
}
