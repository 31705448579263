import React from 'react'
import { FilterIcon } from 'components/Icons'
import './FilterDropDown.scss'

export default function FilterDropDown({
  selectedItem,
  onItemChanged,
  menuItems,
}) {
  return (
    <div className="filter-container">
      <details role="list" className="details-container">
        <summary className="summary-container">
          <FilterIcon />
        </summary>
        <ul role="listbox">
          {menuItems.map(({ text, value }) => (
            <li
              className={value === selectedItem ? 'selected' : ''}
              onClick={() => onItemChanged(value)}
              key={value}
            >
              <p>{text}</p>
            </li>
          ))}
        </ul>
      </details>
    </div>
  )
}
