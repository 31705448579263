import React from 'react'
import './CompanyDetails.scss'

export default function CompanyDetails({ data, children }) {
  return (
    <div className="company-details">
      <h3>{data?.name}</h3>
      <div className="grid">
        <div className="details-panel">
          <label>ABN</label>
          <p>{data?.abn || ''}</p>
          <label>Builders Registration No.</label>
          <p>{data?.buildersRegNumber || ''}</p>
          <label>Office Phone Number</label>
          <p>{data?.phone || ''}</p>
          <label>Office Email Address</label>
          <p>{data?.email || ''}</p>
          <label>Office Address</label>
          <p>{data?.address || ''}</p>
          <label>Postal Address</label>
          <p>{data?.postalAddress || ''}</p>
        </div>
        {data?.image && (
          <div className="company-logo">
            <label>Profile Image</label>
            <article>
              <img src={data?.image?.url} alt="profile_image" />
            </article>
          </div>
        )}
        {children}
      </div>
    </div>
  )
}
