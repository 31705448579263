export const HighVis = 'high_vis'
export const SafetyBoots = 'safety_boots'
export const HardHat = 'hard_hat'
export const Gloves = 'gloves'
export const HearingProtection = 'hearing_protection'
export const EyeProtection = 'eye_protection'
export const WeldingMask = 'welding_mask'
export const SafetyHarness = 'safety_harness'
export const DustMask = 'dust_mask'
export const FirstAid = 'first_aid'

export const toList = () => ([
    HighVis,
    SafetyBoots,
    HardHat,
    Gloves,
    HearingProtection,
    EyeProtection,
    WeldingMask,
    SafetyHarness,
    DustMask,
    FirstAid
])