export const sortingByName = [
  {
    text: 'Sort A-Z',
    value: 'fullName.string.asc',
  },
  {
    text: 'Sort Z-A',
    value: 'fullName.string.desc',
  },
]

export const sortingByEmailAddress = [
  {
    text: 'Sort A-Z',
    value: 'contactEmail.string.asc',
  },
  {
    text: 'Sort Z-A',
    value: 'contactEmail.string.desc',
  },
]

export const filterByRole = [
  { text: 'Admin', value: 'role.string.CompanyAdmin' },
  { text: 'Supervisor', value: 'role.string.CompanySupervisor' },
]

export const sortingByCount = [
  {
    text: 'Sort Low-High',
    value: 'projectCount.number.asc',
  },
  {
    text: 'Sort High-Low',
    value: 'projectCount.number.desc',
  },
]
