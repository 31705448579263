import React from 'react'
import { BackButton, InviteUser } from 'components'
import { PageTitle } from 'context/PageTitleContext'
import './Add.scss'
const { useParams } = require('react-router-dom')

export default function Add({ role }) {
  const { clientId } = useParams()

  return (
    <div className="create-admin-user">
      <PageTitle title="New Client" />
      <BackButton />
      <InviteUser
        companyId={clientId}
        role={role}
        title={'Create Admin User'}
      />
    </div>
  )
}
