import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { RightArrowIcon } from 'components/Icons'
import { FilterDropDown, TableActions, EmptyList } from 'components'
import { getCompanyEmployees } from 'services/clients.svc'
import {
  sortingByName,
  filterByRole,
  sortingByCount,
  sortingByEmailAddress,
} from './constants'
import { useTableActions } from 'hooks/useTableActions'
import { PageTitle } from '../../../../context/PageTitleContext'
import './List.scss'

const List = () => {
  const { companyId } = useParams()
  const { data } = useQuery(['employee'], () => getCompanyEmployees(companyId))
  const { setSearch, setFilter, setSort, tableActions, list } =
    useTableActions(data)
  const navigate = useNavigate()

  return (
    <>
      <PageTitle title="Employees" />
      <div className="list-container">
        <TableActions
          title={'Employee List'}
          searchTerm={tableActions.search}
          onSearchTermChanged={(e) => setSearch(e.target.value)}
        />
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th scope="col">
                  <div>
                    Full Name
                    <FilterDropDown
                      menuItems={sortingByName}
                      onItemChanged={(sortingValue) => setSort(sortingValue)}
                      selectedItem={tableActions.sort}
                    />
                  </div>
                </th>
                <th scope="col">
                  <div>
                    Role
                    <FilterDropDown
                      menuItems={filterByRole}
                      onItemChanged={(filterValue) => setFilter(filterValue)}
                      selectedItem={tableActions.filter}
                    />
                  </div>
                </th>
                <th scope="col">
                  <div>Phone Number</div>
                </th>
                <th scope="col">
                  <div>
                    Email Address
                    <FilterDropDown
                      menuItems={sortingByEmailAddress}
                      onItemChanged={(sortingValue) => setSort(sortingValue)}
                      selectedItem={tableActions.sort}
                    />
                  </div>
                </th>
                <th scope="col">
                  <div>
                    Projects{' '}
                    <FilterDropDown
                      menuItems={sortingByCount}
                      onItemChanged={(sortingValue) => setSort(sortingValue)}
                      selectedItem={tableActions.sort}
                    />
                  </div>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {list &&
                list.map((item, inx) => {
                  return (
                    <tr key={inx} onClick={() => navigate(`${item._id}`)}>
                      <td>{item.fullName}</td>
                      <td>
                        {item.role === 'CompanyAdmin' ? 'Admin' : 'Supervisor'}
                      </td>
                      <td>{item.phone}</td>
                      <td>{item.contactEmail}</td>
                      <td> {item.projectCount} </td>
                      <td>
                        <RightArrowIcon fill={'var(--font-color)'} />
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>
        {list.length ? null : <EmptyList />}
      </div>
    </>
  )
}

export default List
