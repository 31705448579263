import React, { createContext, useContext, useEffect, useState } from 'react'

const PageTitleContext = createContext()
function PageTitleProvider({ children }) {
  const [title, setTitle] = useState('')

  return (
    <PageTitleContext.Provider value={{ title, setTitle }}>
      {children}
    </PageTitleContext.Provider>
  )
}

const usePageTitle = () => {
  const context = useContext(PageTitleContext)

  if (context === undefined) {
    throw new Error('usePageTitle must be used within a ContextProvider')
  }

  const value = { ...context }
  return value
}

function PageTitle({ title }) {
  const { setTitle } = useContext(PageTitleContext)
  useEffect(() => setTitle && title && setTitle(title), [title, setTitle])
  return null
}

export { PageTitleProvider, PageTitle, usePageTitle }
