import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { BackButton } from 'components'
import EditProfileForm from './EditProfileForm'
import { getMyProfile } from 'services/clients.svc'
import './EditProfile.scss'
import { PageTitle } from 'context/PageTitleContext'

const EditProfile = () => {
  const { profileId } = useParams()

  const {
    data: userDetail,
    isLoading,
    error,
  } = useQuery(['user', profileId], () => getMyProfile())

  return (
    <div className="edit-profile-container body-container grid">
      <PageTitle title="My Profile" />
      <BackButton />
      {isLoading ? (
        <article aria-busy="true">Loading, Please Wait</article>
      ) : userDetail ? (
        <EditProfileForm userDetail={userDetail} />
      ) : (
        <p>{error.message || 'Something went wrong :('}</p>
      )}
    </div>
  )
}

export default EditProfile
